import axios from 'axios';
import { toast } from 'vue3-toastify';

const releases = {
  state: () => ({
    releases: [],
    languages: [],

    releaseTypes: ['SINGLE', 'EP', 'ALBUM'],

    composerRoles: []
  }),
  actions: {
    fetchAllReleases: function ({ commit }, options) {
      return new Promise((resolve, reject) => {
        axios.get('/all-releases', {
          params: {
            page: options?.page ?? 1,
            take: options?.take ?? 50,
            status: options?.status ?? undefined,
            transfered: options?.transfered ?? undefined,
            search: options?.search ?? undefined,
            order: options?.order ?? undefined,
          },
        }).then(response => {
          commit('updateReleases', response.data.data);
          resolve(response);
        }).catch(error => {
          reject(error);
          toast.error("Не удалось получить данные релизов.");
        });
      });
    },

    // This route isn't implemented in the backend yet (16 May 2024)
    fetchUserReleases: function (_, { userUuid, options }) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/${userUuid}/releases`, {
          params: {
            page: options?.page ?? 1,
            take: options?.take ?? 50,
            status: options?.status ?? undefined,
            transfered: options?.transfered ?? undefined,
            order: options?.order ?? undefined,
          },
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
          toast.error("Не удалось получить данные релизов пользователя.");
        });
      });
    },

    acceptRelease({ commit }, { release_uuid, release_upc }) {
      return new Promise((resolve, reject) => {
        axios.post(`/accept-release`, {
          release_uuid,
          release_upc
        }).then(response => {
          const status = 'PUBLISHED';
          const release = { ...response.data, status };
          commit('updateReleaseStatus', { uuid: release_uuid, status })
          resolve(release);
        }).catch(e => {
          reject(e)
          toast.error("Не удалось опубликовать релиз.");
        })
      })
    },

    moderateRelease({ commit }, { uuid }) {
      return new Promise((resolve, reject) => {
        axios.post(`/release/${uuid}/moderate`).then(response => {
          const status = 'MODERATED';
          const release = { ...response.data, status };
          commit('updateReleaseStatus', { uuid, status })
          resolve(release);
        }).catch(e => {
          reject(e)
          toast.error("Не удалось одобрить релиз.");
        })
      })
    },

    denyRelease({ commit }, { uuid, deny_reason }) {
      return new Promise((resolve, reject) => {
        axios.post(`/release/${uuid}/deny`, { deny_reason }).then(response => {
          const status = 'DENIED';
          const release = { ...response.data, status };
          commit('updateReleaseStatus', { uuid, status })
          resolve(release);
        }).catch(e => {
          reject(e)
          toast.error("Не удалось отклонить релиз.");
        })
      })
    },

    fetchRelease({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/release/${uuid}`).then(response => {
          commit('addRelease', response.data)
          resolve(response)
        }).catch(e => {
          reject(e)
          toast.error("Не удалось получить данные релиза.");
        })
      })
    },

    updateRelease({ commit }, release) {
      console.log(commit)
      return new Promise((resolve, reject) => {
        axios.patch(`/release/${release.uuid}`, {
          title: release.title,
          title_version: release.title_version,
          language_id: release.language_id,
          type: release.type,
          primary_music_style_id: release.primary_music_style_id,
          secondary_music_style_id: release.secondary_music_style_id,
          release_date: release.release_date,
          copyright: release.copyright,
          cover_uuid: release.cover_uuid,
        }).then(response => {
          toast.success("Релиз обновлён");
          resolve(response)
        }).catch(e => {
          toast.error("Ошибка обновления релиза");
          console.log(e)
          reject(e)
        })
      })
    },

    updateTrack({ commit }, track) {
      return new Promise((resolve, reject) => {
        axios.patch(`/track/${track.uuid}`, {
          artist_uuid: track.artist_uuid,
          language_id: track.language_id,
          title: track.title,
          title_version: track.title_version,
          lyrics: track.lyrics,
          primary_music_style_id: track.primary_music_style_id,
          secondary_music_style_id: track.secondary_music_style_id,
          preview_start_seconds: track.preview_start_seconds,
          explicit: track.explicit,
          instrumental: track.instrumental,
          wav_uuid: track.wav_uuid,
        }).then(response => {
          toast.success("Трек обновлён");
          commit('updateRelease', track)
          resolve(response)
        }).catch(e => {
          toast.error("Ошибка обновления трека");
          reject(e)
        })
      })
    },

    // languages
    fetchLanguages: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/languages').then(response => {
          commit('setLanguages', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
          toast.error("Не удалось получить данные языков");
        });
      });
    },

    deleteRelease: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/release/${releaseUuid}`).then(response => {
          commit('removeRelease', releaseUuid);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    changeReleaseAuthor({commit}, {releaseUuid, userUuid}) {
      return new Promise((resolve, reject) => {
        axios.patch(`/release/${releaseUuid}/author/${userUuid}`).then(response => {
          toast.success("Пользователь релиза был изменён.")
          commit('addRelease', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
          toast.error("Не удалось изменить пользователя релиза");
        });
      });
    },

    /*COMPOSERS */
    fetchComposerRoles({commit}){
      return new Promise( (resolve, reject) => {
          axios.get(`/composer-roles`).then(response => {
              commit('replaceComposerRoles',Object.keys(response.data))
              resolve(response)
          }).catch(e => {
            reject(e)
            toast.error("Не удалось получить данные о роля авторов");
          })
      })
    },

    editComposer({commit},{uuid, name, surname, patronymic, role, trackUuid, releaseUuid}){
        return new Promise((resolve, reject) => {
            axios.patch(`/composer/${uuid}`,{
                name,
                surname,
                patronymic,
                role
            })
            .then(response => {
              toast.success("Автор успешно обновлён");
              commit('updateComposer',{trackUuid, releaseUuid, composer: response.data})
              resolve(response)
            })
            .catch(error => {
              toast.error("Не удалось обновить автора");
              reject(error)
            })
        })
    },

    deleteComposer({commit},{uuid, trackUuid, releaseUuid}){
      console.log('deleteCOmposer')
        return new Promise((resolve, reject) => {
            axios.delete(`/composer/${uuid}`)
            .then(response => {
              toast.success("Автор успешно удалён");
              commit('removeComposer', {uuid, trackUuid, releaseUuid})
              resolve(response)
            })
            .catch(error => {
              toast.error("Не удалось удалить автора");
              reject(error)
            })
        })
    },
  },
  mutations: {
    updateReleases(state, releases) {
      state.releases = releases
    },

    addRelease(state, release) {
      release.tracks.forEach(track => {
        track.composers = track.composers.map(composer => ({
          ...composer,
          name: composer.name ?? '',
          surname: composer.surname ?? '',
          patronymic: composer.patronymic ?? ''
        }))
      });
      const index = state.releases.findIndex(state_release => state_release.uuid == release.uuid)
      if (index != -1){
        state.releases[index] = release
      }else{
        state.releases.push(release);
      }
    },

    updateReleaseStatus(state, { uuid, status }) {
      const index = state.releases.findIndex(release => release.uuid == uuid)
      if (index != -1)
        state.releases[index].status = status
    },

    updateReleaseDownloadStatus(state, { uuid, status }) {
      const index = state.releases.findIndex(release => release.uuid == uuid)
      if (index != -1){
        state.releases[index].isDownloading = status
      }
    },

    updateRelease(state, updatedRelease) {
      const index = state.releases?.findIndex(stateRelease => stateRelease.uuid == updatedRelease.uuid);
      if (index > -1) {
        // Создать копию объекта release, чтобы сохранить ссылку
        const releaseCopy = { ...state.releases[index], ...updatedRelease };
        // Обновить объект в массиве с сохранением ссылки
        state.releases[index] = releaseCopy;
      }
    },

    setLanguages: function (state, languages) {
      state.languages = languages;
    },

    removeRelease: function (state, releaseUuid) {
      const index = state.releases.findIndex(release => release.uuid == releaseUuid);
      if (index > -1) {
        state.releases.splice(index, 1);
      }
    },

    /*COMPOSERS*/
    replaceComposerRoles(state, composerRoles){
      state.composerRoles = composerRoles
    },

    updateComposer(state, {trackUuid, releaseUuid, composer}){
      const release = state.releases.find(release => release.uuid == releaseUuid)
      if(release){
        const track = release.tracks.find(track => track.uuid == trackUuid)
        const idx = track.composers.findIndex(trackComposer => trackComposer.uuid == composer.uuid)
        if(idx != -1){
          track.composers.splice(idx,1,composer)
        }
      }
    },

    removeComposer(state, {trackUuid, releaseUuid, uuid}){
      console.log('removeCOmposer')
      const release = state.releases.find(release => release.uuid == releaseUuid)
      if(release){
        const track = release.tracks.find(track => track.uuid == trackUuid)
        const idx = track.composers.findIndex(trackComposer => trackComposer.uuid == uuid)
        if(idx != -1){
          console.log('removeCOmposer idx', idx)
          track.composers.splice(idx,1)
        }
      }
    }
  },
  getters: {
    getReleases: state => state.releases,
    getReleaseByUUID: state => uuid => state.releases.find(release => release.uuid == uuid),

    getReleaseTypes: state => state.releaseTypes,
    getLanguage: state => id => state.languages.find(language => language.languageId == id),
    getLanguages: state => state.languages,

    /* COMPOSERS */
    getComposersRoles: state => state.composerRoles,
    getReleaseProducts: state => uuid => state.releases.find(release => release.uuid == uuid)?.userProducts
  },
};

export default releases;
