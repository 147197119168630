<template>
  <div
    :class="[
      'chip',
      'chip--' + color,
      { 'chip--small': $attrs.small != undefined },
      { 'chip--loading': $attrs.loading != undefined },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.chip {
  padding: 6px 12px;
  border-radius: 9px;
  width: min-content;

  &--small {
    padding: 3px 6px;
    font-size: 13px;
    border-radius: 6px;
  }

  &--gray {
    color: #949494;
    border: 1.2px solid #949494;
  }

  &--red {
    color: #c54747;
    border: 1.2px solid #c54747;
  }

  &--orange {
    color: #d18718;
    border: 1.2px solid #d18718;
  }

  &--yellow {
    color: #cea130;
    border: 1.2px solid #cea130;
  }

  &--green {
    color: #518b4c;
    border: 1.2px solid #518b4c;
  }

  &--loading {
    color: #949494;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 800px 100%;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
</style>
