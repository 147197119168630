import { mapActions, mapGetters } from "vuex";
import getProductAlias from "@/utils/getProductAlias";
import getPaymentAlias from "@/utils/getPaymentAlias";

export default {

  props:{
    releaseUuid: String,
  },

  data(){
    return {
      isReleaseProductsDataLoading: false,
      isReleaseProductsDataError: false
    }
  },

  async mounted() {
    try{
      this.isReleaseProductsDataLoading = true
      if (!this.products.length) {
        await this.fetchProducts();
      }
  
      if (!this.getReleaseProducts(this.releaseUuid)) {
        this.fetchRelease(this.releaseUuid);
      }
  
      await Promise.all(
        this.getReleaseProducts(this.releaseUuid).map(
          async (userReleaseProduct) => {
            const userProduct = this.userProduct(
              userReleaseProduct.user_product_uuid
            );
            if (!userProduct) {
              await this.fetchUserProduct(userReleaseProduct.user_product_uuid);
            }
          }
        )
      );
    }catch(e){
      this.isReleaseProductsDataError = true
    }finally{
      this.isReleaseProductsDataLoading = false
    }
  },

  methods: {
    ...mapActions(["fetchProducts", "fetchUserProduct"]),

    getProductAlias,
    getPaymentAlias,

    formatDate(date) {
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Date(date).toLocaleDateString("ru-RU", options);
    },
  },

  computed: {
    ...mapGetters([
      "products",
      "getReleaseProducts",
      "usersPurchases",
      "userProduct",
    ]),

    releaseProducts() {
      const releaseProducts = this.getReleaseProducts(this.releaseUuid);
      const preparedReleaseProducts = this.usersPurchases.filter((purchase) =>
        releaseProducts.find(
          (releaseProduct) => releaseProduct.user_product_uuid == purchase.uuid
        )
      );

      return preparedReleaseProducts;
    },

    isFreePayment() {
      return !this.releaseProducts.length;
    },

    isSubscriptionPayment() {
      return this.releaseProducts.find(releaseProduct => releaseProduct.paymentType == 'SUBSCRIPTION')
    },

    isOrderPayment() {
      return this.releaseProducts.find(releaseProduct => releaseProduct.paymentType != 'SUBSCRIPTION')
    },

    total() {
      return this.releaseProducts
        .filter(
          (releaseProduct) =>
            releaseProduct.paymentType != "SUBSCRIPTION" &&
            releaseProduct.paymentType != "ISSUED"
        )
        .reduce(
          (acc, releaseProduct) =>
            acc + parseFloat(releaseProduct.product.amount),
          0
        );
    },

    productWord() {
      const pluralRules = new Intl.PluralRules("ru-RU");
      const rule = pluralRules.select(this.releaseProducts.length);

      const product = {
        one: "услуга",
        few: "услуги",
        many: "услуг",
      };

      const productWord = product[rule] || "услуг";
      return productWord;
    },
  }
}