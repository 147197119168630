<template>
  <span style="color: white"> </span>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    const today = new Date();
    const twoWeeksAgo = new Date(today);
    twoWeeksAgo.setDate(today.getDate() - 14);
    this.fetchPurchases({
      startDate: twoWeeksAgo,
      endDate: today
    });
  },
  methods: {
    ...mapActions(["fetchPurchases"]),
  },
};
</script>

<style lang="scss">
@import url("@/assets/_global.scss");
@import url("@/assets/_nullstyle.scss");
@import url("@/assets/vue-animation.scss");

body {
  background: #1f2023;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
</style>
