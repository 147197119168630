<template>
  <div class="order-info" @click.stop>
    <!-- LOADING -->
    <div
      class="order-info-wrapper--loading"
      v-if="isReleaseProductsDataLoading"
    >
      <div class="title">Данные загружаются...</div>
      <div class="products">
        <table>
          <tr>
            <th>услуга</th>
            <th>стоимость</th>
            <th>метод</th>
            <th>дата</th>
          </tr>
          <tr v-for="i in 3" :key="i">
            <td>загрузка</td>
            <td>загрузка</td>
            <td>загрузка</td>
            <td>загрузка</td>
          </tr>
        </table>
      </div>
      <div class="title total" v-if="releaseProducts.length"></div>
    </div>
    <!-- ERROR -->
    <div
      class="order-info-wrapper--error"
      v-else-if="isReleaseProductsDataError"
    >
      <div class="title">При запросе данных произошла ошибка</div>
      <div class="products">
        <table>
          <tr>
            <th>услуга</th>
            <th>стоимость</th>
            <th>метод</th>
            <th>дата</th>
          </tr>
          <tr v-for="i in 1" :key="i">
            <td>ошибка</td>
            <td>ошибка</td>
            <td>ошибка</td>
            <td>ошибка</td>
          </tr>
        </table>
      </div>
      <div class="title total" v-if="releaseProducts.length"></div>
    </div>
    <!-- DATA -->
    <div class="order-info-wrapper" v-else>
      <div class="title" v-if="releaseProducts.length">
        В данном релизе {{ releaseProducts.length }} {{ productWord }}:
      </div>
      <div class="title" v-else>В данном релизе нет платных услуг</div>
      <div class="products" v-if="releaseProducts.length">
        <table>
          <tr>
            <th>услуга</th>
            <th>стоимость</th>
            <th>метод</th>
            <th>дата</th>
          </tr>
          <tr
            v-for="releaseProduct in releaseProducts"
            :key="releaseProduct.uuid"
          >
            <td>{{ getProductAlias(releaseProduct.product.name) }}</td>
            <td>{{ releaseProduct.product.amount }}$</td>
            <td>{{ getPaymentAlias(releaseProduct.paymentType) }}</td>
            <td>{{ formatDate(releaseProduct.createdAt) }}</td>
          </tr>
        </table>
      </div>
      <div class="title" v-if="releaseProducts.length">
        Общая сумма: {{ total }}$
      </div>
    </div>
  </div>
</template>

<script>
import releaseProductsMixin from "@/mixins/releaseProductsMixin";

export default {
  mixins: [releaseProductsMixin],
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";


.release-purchase-status-chip {
  display: flex;
  flex-direction: column;
  position: relative;

  .products-count {
    font-size: 13px;
    margin-bottom: 2px;
    color: #bbbbbb;
    font-weight: 600;
  }

  .chip-wrapper {
    display: flex;

    .chip {
      margin-right: 7px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.order-info {
  z-index: 1;
  border-radius: 10px;

  .title {
    color: #949494;
    padding: 5px 0px;
    white-space: nowrap;
    width: min-content;
  }

  .products {
    margin: 10px 0px 20px 0px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0px;
    tr,
    td,
    th {
      background: transparent;
      padding: 5px 10px;
      border: 1px solid #1f2023;
      border-radius: 0px;
      white-space: nowrap;
      text-align: center;
    }

    tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.order-info-wrapper--loading {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0px;

    color: #949494;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 800px 100%;
    animation: loading 1.5s infinite;
    tr td:first-child {
      width: 50%;
    }
  }
}

.order-info-wrapper--error {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0px;

    color: $red;
    tr td:first-child {
      width: 50%;
    }
  }
}

@keyframes loading {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
</style>