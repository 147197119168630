import PurchaseType from '@/utils/PurchaseType';
import axios from 'axios';
import { toast } from 'vue3-toastify';


const purchases = {
  state: () => ({
    usersPurchases: [],

    products: [],
    subscriptions: [],

    fetchProductsPromise: null
  }),
  actions: {
    fetchPurchases({ commit }, { startDate, endDate }) {
      const params = {
        startDate,
        endDate,
        order: 'DESC',
      };
      return new Promise((resolve, reject) => {
        axios.get(`/purchases`, { params }).then(response => {
          commit('setUserPurchases', response.data.data)
          resolve(response)
        }).catch(e => {
          reject(e)
          toast.error("Не удалось получить данные о платежах.");
        })
      })
    },

    fetchUserPurchases({ commit }, userUuid) {
      const today = new Date();

      const startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 3);

      const endDate = new Date(today);
      endDate.setFullYear(today.getFullYear() + 3);

      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        order: 'DESC',
      };

      return new Promise((resolve, reject) => {
        axios.get(`/user/${userUuid}/purchases`, { params }).then(response => {
          commit('setUserPurchases', response.data.data)
          resolve(response)
        }).catch(e => { reject(e); toast.error("Не удалось получить данные о платежах пользователя."); })
      })
    },
    fetchUserProduct({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/user-product/${uuid}`).then(response => {
          commit('setUserProduct', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    updateUserProduct({ commit }, { uuid, data }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/user-product/${uuid}`, data).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('updateOrCreateUserPurchase', userPurchase)
          toast.success("Данные покупки успешно обновлены!")
          resolve(response)
        }).catch(e => { reject(e); toast.error("Не удалось обновить данные покупки.") })
      })
    },
    updateUserSubscription({ commit }, { uuid, data }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/user-subscription/${uuid}`, data).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          commit('updateOrCreateUserPurchase', userPurchase)
          toast.success("Данные покупки успешно обновлены!")
          resolve(response)
        }).catch(e => { reject(e); toast.error("Не удалось обновить данные покупки.") })
      })
    },

    issueUserProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/user/product', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('updateOrCreateUserPurchase', userPurchase)
          toast.success('Продукт был успешно выдан.')
          resolve(response)
        }).catch(e => {reject(e); toast.error('При выдаче продукта произошла ошибка')})
      })
    },
    issueUserSubscription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/user/subscription', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          commit('updateOrCreateUserPurchase', userPurchase)
          toast.success('Подписка успешно выдана.')
          resolve(response)
        }).catch(e => {reject(e); toast.error('При выдачи подписки произошла ошибка')})
      })
    },

    fetchProducts({ commit, state }) {
      if (!state.fetchProductsPromise) {
        state.fetchProductsPromise = new Promise((resolve, reject) => {
          axios.get('/products').then(response => {
            commit('setProducts', response.data)
            resolve(response)
          }).catch(e => reject(e))
        })
      }

      return state.fetchProductsPromise
    },
    fetchSubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/subscriptions').then(response => {
          commit('setSubscriptions', response.data)
          resolve(response)
        }).catch(e => reject(e))
      })
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setUserProduct(state, userProduct) {
      const userProductExistIndex = state.usersPurchases.filter(purchase => purchase.type == 'PRODUCT').findIndex(purchase => purchase.uuid == userProduct.uuid)
      const newUserPurchase = { ...userProduct, type: 'PRODUCT' }
      if (userProductExistIndex != -1) {
        Object.assign(state.usersPurchases[userProductExistIndex], newUserPurchase);
      } else {
        state.usersPurchases.push(newUserPurchase)
      }
    },
    setUsersPurchases(state, purchases) {
      console.log('p', purchases)
      state.usersPurchases = purchases;
    },
    setUserPurchases(state, purchases) {
      // Объединяем текущие и новые покупки
      const allPurchases = [...state.usersPurchases, ...purchases];

      // Разделяем покупки по типам
      const products = [];
      const subscriptions = [];

      allPurchases.forEach(purchase => {
        if (purchase.type === "PRODUCT") {
          if (!products.some(p => p.uuid === purchase.uuid)) {
            products.push(purchase);
          }
        } else if (purchase.type === "SUBSCRIPTION") {
          if (!subscriptions.some(p => p.uuid === purchase.uuid)) {
            subscriptions.push(purchase);
          }
        }
      });

      // Объединяем обратно уникальные покупки
      state.usersPurchases = [...products, ...subscriptions];
    },

    updateOrCreateUserPurchase(state, purchaseToUpdate) {
      const userPurchasesIndex = state.usersPurchases.findIndex(purchase => purchase.uuid == purchaseToUpdate.uuid);
      if (userPurchasesIndex != -1) {
        Object.assign(state.usersPurchases[userPurchasesIndex], purchaseToUpdate)
      } else {
        state.usersPurchases.push(purchaseToUpdate)
      }
    }
  },
  getters: {
    usersPurchases: state => state.usersPurchases,
    userPurchases: state => uuid => state.usersPurchases.filter(purchase => purchase.userUuid == uuid) ?? [],
    userProduct: state => uuid => state.usersPurchases.filter(userPurchase => userPurchase.type == 'PRODUCT').find(userProduct => userProduct.uuid == uuid),

    products: state => state.products,
    subscriptions: state => state.subscriptions,
  },
};

export default purchases;
