<template>
  <div
    :class="[
      'release-purchase-status-chip',
      'release-purchase-status-chip--loading',
    ]"
  >
    <!-- <div class="products-count">3 услуги</div> -->
    <div class="chip-wrapper" v-if="isReleaseProductsDataLoading">
      <Chip small loading >Загрузка</Chip
      >
    </div>
    <div class="chip-wrapper" v-else>
      <Chip :color="'yellow'" small v-show="isSubscriptionPayment"
        >Подписка</Chip
      >
      <Chip :color="'yellow'" small v-show="isOrderPayment"
        >Оплата: {{ total }}$</Chip
      >
      <Chip :color="'gray'" small v-show="isFreePayment">Бесплатно</Chip>
    </div>

    <div class="order-info" @click.stop>
      <div class="title" v-if="releaseProducts.length">
        В данном релизе {{ releaseProducts.length }} {{ productWord }}:
      </div>
      <div class="title" v-else-if="isReleaseProductsDataLoading">
        Данные загружаются...
      </div>
      <div class="title" v-else>В данном релизе нет платных услуг</div>
      <div class="products" v-if="releaseProducts.length">
        <table>
          <tr>
            <th>услуга</th>
            <th>стоимость</th>
            <th>метод</th>
            <th>дата</th>
          </tr>
          <tr
            v-for="releaseProduct in releaseProducts"
            :key="releaseProduct.uuid"
          >
            <td>{{ getProductAlias(releaseProduct.product.name) }}</td>
            <td>{{ releaseProduct.product.amount }}$</td>
            <td>{{ getPaymentAlias(releaseProduct.paymentType) }}</td>
            <td>{{ formatDate(releaseProduct.createdAt) }}</td>
          </tr>
        </table>
      </div>
      <div class="title" v-if="releaseProducts.length">
        Общая сумма: {{ total }}$
      </div>
    </div>
  </div>
</template>

<script>
import releaseProductsMixin from '@/mixins/releaseProductsMixin'
import Chip from "@/components/Chip.vue";

export default {
  components: {
    Chip,
  },
  mixins:[releaseProductsMixin]
};
</script>

<style lang="scss" scoped>
.release-purchase-status-chip {
  display: flex;
  flex-direction: column;
  position: relative;

  .products-count {
    font-size: 13px;
    margin-bottom: 2px;
    color: #bbbbbb;
    font-weight: 600;
  }

  .chip-wrapper {
    display: flex;

    .chip {
      margin-right: 7px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &:hover {
    .order-info {
      display: block;
    }
  }
}

.order-info {
  position: absolute;
  padding: 20px;
  background: rgba($color: #000000, $alpha: 0.5);
  backdrop-filter: blur(12px);
  display: none;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #4e4e4e;
  // left: -200px;
  transform: translateX(-40%);

  .title {
    white-space: nowrap;
  }

  .products {
    margin: 10px 0px 20px 0px;
  }

  table {
    border-collapse: collapse;
    border-radius: 0px;
    tr,
    td,
    th {
      background: transparent;
      padding: 5px 10px;
      border: 1px solid #4e4e4e;
      border-radius: 0px;
      white-space: nowrap;
      text-align: center;
    }

    tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
</style>