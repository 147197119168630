<template>
  <button class="iconBtn" v-bind="$attrs">
    <SvgIcon type="mdi" :path="icon" />
    <span v-if="tooltip" class="tooltip">{{ tooltip }}</span>
  </button>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';

export default {
  props:{
    icon: Object,
    tooltip: String
  },
  components: {
    SvgIcon
  },
};
</script>

<style lang="scss">

.iconBtn{
  position: relative;
  cursor: pointer;

  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 4px;
  border:none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;

  img{
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  &:enabled:hover{
    background:#61646a;
  }

  &:disabled{
    color: #61646a;
    cursor: not-allowed;
  }

  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 10;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  
    &:after {
      content: "";
      position: absolute;
      top: 100%; /* Arrow pointing down */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
  
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

</style>
