export default function getProductAlias(productName) {
  switch (productName) {
    case 'MYLABEL_EARLY_DATE':
      return 'Ускоренный выпуск релиза';
    case 'MYLABEL_CUSTOM_PC_LINE':
      return 'Авторский Copyright';
    case 'MYLABEL_PRODUCER_ARTIST':
      return 'В релизе есть артист с ролью «Продюсер»';
    default:
      return productName;
  }
}
