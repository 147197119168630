export default function getPaymentAlias(paymentType) {
  switch (paymentType) {
    case 'PAY_PAL':
      return 'PAY PAL';
    case 'CRYPTOCLOUD':
      return 'CRYPTOCLOUD';
    case 'ISSUED':
      return 'Администрация';
    case 'SUBSCRIPTION':
      return 'По подписке';
    default:
      return paymentType;
  }
}
