<template>
  <Modal v-bind="$props" @close="closeUserModal">
    {{ selectedProductPurchaseName }}
    <!-- ADD PURCHASES MODAL -->
    <Modal v-show="isAddPurchaseMode" @close="closeAddPurchaseModal">
      <div class="add-purchase-modal">
        <div class="body">
          <h2>Добавление покупки пользователю</h2>
          <p class="title">тип покупки</p>
          <Select
            :placeholder="'Выберите тип покупки'"
            :items="purchaseTypes"
            v-model="selectedPurchaseType"
          />
          <div
            class="add-purchase-block"
            v-if="selectedPurchaseType == 'PRODUCT'"
          >
            <p class="title">название продукта</p>
            <Select
              :placeholder="'Выберите название продукта'"
              :items="productWithAlias"
              v-model="selectedProductPurchaseName"
            />
          </div>
          <div
            class="add-purchase-block"
            v-if="selectedPurchaseType == 'SUBSCRIPTION'"
          >
            <p class="title">название подписки</p>
            <Select
              :placeholder="'Выберите название подписки'"
              :items="subscriptionWithAlias"
              v-model="selectedSubscriptionPurchaseName"
            />
            <p class="title">длительность подписки</p>
            <div class="date-range">
              <VueDatePicker
                dark
                v-model="subscriptionDateRange"
                multi-calendars
                range
                :enableTimePicker="false"
              />
            </div>
          </div>
          <div class="result" v-show="canIssuePurchase">
            <p class="icon-title">
              <SvgIcon type="mdi" :path="icons.matrix" /> Итоговая информация по
              выдаче покупки:
            </p>
            <p class="title">
              <span>Пользователь: </span>{{ user.email }} <br />
              <span>Тип покупки:</span>
              {{ selectedPurchaseType == "PRODUCT" ? "Продукт" : "Подписка" }}
              <br />

              <span>
              Название
                {{
                  selectedPurchaseType == "PRODUCT" ? "продукта" : "подписки"
                }}:
              </span>
              {{ selectedPurchaseName }}
            </p>
            <p class="title"></p>

            <p class="icon-title">
              <SvgIcon type="mdi" :path="icons.calendar" />Выбранный диапазон
              подписки:
            </p>
            
            <p class="title--focus" v-show="invalidSubscriptionDateRange">
              пожалуйста выберите диапазон дат
            </p>
            <p
              class="title"
              v-if="
                subscriptionDateRange && selectedPurchaseType == 'SUBSCRIPTION'
              "
            >
              <span>начало: </span>
              {{ getFormattedDate(subscriptionDateRange[0]) }}
              <span>конец: </span
              >{{ getFormattedDate(subscriptionDateRange[1]) }} <br />
              <span>Всего:</span>
              {{ dateDiff(subscriptionDateRange[0], subscriptionDateRange[1]) }}
            </p>
            <p class="title">
              Стоимость: <span>{{ totalPurchaseCost }} $</span>
            </p>
          </div>
          <div class="actions">
            <Button
              :loading="isIssuePurchaseLoading"
              :disabled="isIssuePurchaseLoading"
              @click="addPurchase"
              >Добавить</Button
            >
            <Button
              :disabled="isIssuePurchaseLoading"
              @click="closeAddPurchaseModal"
              gray
              >Отмена</Button
            >
          </div>
        </div>
      </div>
    </Modal>
    <!-- END ADD PURCHASES MODAL -->

    <div class="user" ref="user">
      <!-- HEADER -->
      <div class="user__info">
        <div class="user__main main">
          <div class="main__avatar">
            <img
              :src="
                file(user?.profile?.avatar_url)?.base64 ??
                require('@/assets/placeholder.png')
              "
              alt=""
            />
          </div>
          <div class="main__info">
            <div class="main__name">
              <span>
                {{ userName }}
              </span>
              <button class="main__copy" @click="copyToClipboard(userName)">
                <svg>
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') + '#copy',
                    }"
                  />
                </svg>
              </button>
            </div>
            <div class="main__email">
              <span>{{ user?.email }}</span>
              <div
                class="main__verification verification"
                v-if="user?.verified_at"
              >
                <svg class="verification__icon verification__icon--verified">
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') + '#check',
                    }"
                  />
                </svg>
                <!-- <svg class="verification__icon verification__icon--unverified" v-else>
                  <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#cross'}" />
                </svg> -->
              </div>
              <button class="main__copy" @click="copyToClipboard(user?.email)">
                <svg>
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') + '#copy',
                    }"
                  />
                </svg>
              </button>
            </div>
            <div class="main__ban" v-if="isUserBanned">
              <SvgIcon type="mdi" :path="icons.banned" />
              <span>Причина блокировки: "{{ user?.ban_reason }}"</span>
            </div>
          </div>
          <div class="main__actions">
            <Button v-if="!isUserBanned" @click="ban" red>
              Заблокировать
            </Button>
            <Button v-else @click="unban" red> Разблокировать </Button>
            <Button blue @click="openAddPurchaseModal">
              Добавить покупку
            </Button>
          </div>
        </div>
        <SelectButtons
          class="select-section-btn"
          :items="sections"
          v-model="activeSection"
          @select="(item) => selectSection(item.value)"
        />
      </div>

      <!--RELEASES-->
      <div class="user__releases" v-if="activeSection == 'RELEASES'">
        <Table>
          <table v-show="releasesList.length">
            <thead>
              <th>Обложка</th>
              <th>Название</th>
              <th>Тип</th>
              <th>
                <button
                  :class="[
                    'table-sortable',
                    {
                      'table-sortable-desc':
                        releasesPagination.options.order == 'DESC',
                    },
                    {
                      'table-sortable-asc':
                        releasesPagination.options.order == 'ASC',
                    },
                  ]"
                  @click="toggleSort()"
                >
                  Дата
                </button>
              </th>
              <th v-show="!releasesPagination.options.status">Статус</th>
              <th>Действия</th>
            </thead>
            <tbody>
              <tr
                v-for="release in releasesList"
                :key="release.uuid"
                @click="selectRelease(release.uuid)"
              >
                <td>
                  <div class="table-cover table-center">
                    <img
                      :src="
                        file(release.cover_uuid)?.base64 ??
                        require('@/assets/placeholder.png')
                      "
                      alt="Cover"
                    />
                  </div>
                </td>
                <td>
                  {{ release.title }}
                </td>
                <td>
                  {{ release.type }}
                </td>
                <td>
                  <div class="info__date">
                    <div>
                      {{ release.release_date }}
                    </div>
                    <div class="info__transfered" v-if="release.transfered_at">
                      <SvgIcon type="mdi" :path="icons.arrowRight" />
                      {{ release.transfered_at }}
                    </div>
                  </div>
                </td>
                <td v-show="!releasesPagination.options.status">
                  <span class="info__status">
                    <StatusChip :status="release.status" />
                  </span>
                </td>
                <td>
                  <div class="table-actions">
                    <button @click.stop="copyReleaseURL(release)">
                      <img
                        src="@/assets/icons/copy.svg"
                        alt=""
                        class="actions__icon"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Table>
        <h3 v-show="!releasesList.length">
          У данного пользователья нет релизов
        </h3>
      </div>

      <!--PURCHASES-->
      <div
        class="user__purchases purchases"
        v-if="activeSection == 'PURCHASES'"
      >
        <h3 v-show="!purchases.length">У данного пользователя нет покупок</h3>
        <div class="user__purchases--table-wrapper" v-show="purchases.length">
          <table>
            <thead>
              <tr>
                <th style="width: 40px">#</th>
                <th>Тип покупки</th>
                <th style="width: 30%">Название</th>
                <th>Статус</th>
                <th>Сумма</th>
                <th style="width: 15%">Метод оплаты</th>
                <th style="width: 15%">Дата</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purchase, index) in purchases" :key="purchase.uuid">
                <td style="color: #949494">{{ index + 1 }}</td>
                <td>
                  <Chip
                    small
                    v-if="purchase.type == 'SUBSCRIPTION'"
                    :color="'yellow'"
                    >Подписка</Chip
                  >
                  <Chip
                    small
                    v-else-if="purchase.type == 'PRODUCT'"
                    :color="'green'"
                    >Продукт</Chip
                  >
                  <Chip small v-else :color="'gray'">Неизвестный</Chip>
                </td>
                <td>
                  {{
                    purchase.type == "SUBSCRIPTION"
                      ? purchase.subscription.name
                      : getProductAlias(purchase.product.name)
                  }}
                </td>
                <td>
                  <Chip
                    small
                    :color="getPaymentStatusColorChip(purchase.status)"
                  >
                    {{ purchase.status }}
                  </Chip>
                </td>
                <td>
                  {{
                    purchase.type == "SUBSCRIPTION"
                      ? purchase.subscription.amount
                      : purchase.product.amount
                  }}$
                </td>
                <td>{{ getPaymentAlias(purchase.paymentType) }}</td>
                <td>{{ getFormattedDate(purchase.createdAt) }}</td>
                <td>
                  <div class="actions">
                    <IconButton
                      @click="issueUserPurchase(purchase)"
                      :tooltip="'Выдать повторно покупку'"
                      :disabled="
                        purchase.status != 'REJECTED' ||
                        purchase.status == 'PENDING'
                      "
                      :icon="icons.gift"
                    />
                    <IconButton
                      @click="rejectUserPurchase(purchase)"
                      :tooltip="'Аннулировать покупку'"
                      :disabled="
                        purchase.status == 'REJECTED' ||
                        purchase.status == 'PENDING'
                      "
                      :icon="icons.reject"
                    />
                    <IconButton
                      @click="copyUserPurchaseID(purchase)"
                      :tooltip="'Скопировать id транзакции'"
                      :icon="icons.copy"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { toast } from "vue3-toastify";
import getProductAlias from "@/utils/getProductAlias";
import getPaymentAlias from "@/utils/getPaymentAlias";
import { mapActions, mapGetters } from "vuex";
import {
  mdiCancel,
  mdiArrowRight,
  mdiDelete,
  mdiGiftOutline,
  mdiMinusCircle,
  mdiDataMatrixScan,
  mdiCalendarClockOutline,
} from "@mdi/js";
import copyToClipboard from "@/utils/copyToClipboard";
import Button from "./Button.vue";
import StatusChip from "./StatusChip.vue";
import Modal from "./Modal.vue";
import Table from "./Table.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import SelectButtons from "./SelectButtons.vue";
import Chip from "./Chip.vue";
import IconButton from "./IconButton.vue";
import Select from "./Select.vue";

export default {
  name: "UserModal",
  components: {
    Modal,
    StatusChip,
    Button,
    Table,
    SvgIcon,
    SelectButtons,
    Chip,
    IconButton,
    Select,
  },
  props: {
    ...Modal.props,
    user: Object,
  },
  data() {
    return {
      isIssuePurchaseLoading: false,
      subscriptionDateRange: [],
      selectedPurchaseType: null,
      selectedSubscriptionPurchaseName: null,
      selectedProductPurchaseName: null,
      isAddPurchaseMode: false,
      purchaseTypes: [
        { id: "PRODUCT", name: "Продукт" },
        { id: "SUBSCRIPTION", name: "Подписка" },
      ],

      icons: {
        banned: mdiCancel,
        arrowRight: mdiArrowRight,
        delete: mdiDelete,
        gift: mdiGiftOutline,
        reject: mdiMinusCircle,
        copy: mdiDataMatrixScan,
        matrix: mdiDataMatrixScan,
        calendar: mdiCalendarClockOutline,
      },

      isBanning: false,

      paymentTypesList: [
        {
          id: "PAY_PAL",
          name: "PayPal",
        },
        {
          id: "CRYPTO_CLOUD",
          name: "CryptoCloud",
        },
        {
          id: "ISSUED",
          name: "Выдано",
        },
      ],

      releases: [],
      releasesPagination: {
        options: {
          page: 1,
          take: 10,
          transfered: undefined,
          order: "ASC",
        },
        meta: {},
      },

      activeSection: null,
      sections: [
        {
          key: "RELEASES",
          value: "RELEASES",
          name: "Релизы",
        },
        {
          key: "PURCHASES",
          value: "PURCHASES",
          name: "Покупки",
        },
      ],
    };
  },
  mounted() {
    this.selectSection(this.sections[0].value);
    this.fetchUserReleasesRequest();
    this.fetchProducts();
    this.fetchSubscriptions();
    this.fetchUserPurchases(this.user.uuid);

    this.selectedPurchaseType = this.purchaseTypes[0].id;
  },
  methods: {
    copyToClipboard,

    ...mapActions([
      "fetchUserReleases",

      "issueUserProduct",
      "issueUserSubscription",
      "updateUserProduct",
      "updateUserSubscription",
      "fetchProducts",
      "fetchSubscriptions",
      "fetchUserPurchases",

      "banUser",
      "unbanUser",
    ]),

    async addPurchase() {
      this.isIssuePurchaseLoading = true;

      try {
        if (this.selectedPurchaseType == "PRODUCT") {
          this.issueUserProduct({
            userUuid: this.user.uuid,
            productName: this.selectedProductPurchaseName,
          });
        } else if (this.selectedPurchaseType == "SUBSCRIPTION") {
          this.issueUserSubscription({
            userUuid: this.user.uuid,
            subscriptionName: this.selectedSubscriptionPurchaseName,
            startAt: this.subscriptionDateRange[0],
            expiresAt: this.subscriptionDateRange[1],
          });
        }
        this.selectSection(this.sections[1].value);
        this.closeAddPurchaseModal();
      } finally {
        this.isIssuePurchaseLoading = false;
      }
    },

    dateDiff(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const diffInMs = end - start;

      const daysCount = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

      const pluralRules = new Intl.PluralRules("ru-RU");
      const rule = pluralRules.select(daysCount);

      const days = {
        one: "день",
        few: "дня",
        many: "дней",
      };

      const word = days[rule] || "дня";
      return `${daysCount} ${word}`;
    },

    /* ADD PURCHASE MODAL */
    closeAddPurchaseModal() {
      this.isIssuePurchaseLoading = false;
      this.subscriptionDateRange = [];
      this.selectedPurchaseType = "PRODUCT";
      this.selectedSubscriptionPurchaseName = null;
      this.selectedProductPurchaseName = null;
      this.isAddPurchaseMode = false;
    },

    openAddPurchaseModal() {
      this.isAddPurchaseMode = true;
    },

    copyUserPurchaseID(purchase) {
      navigator.clipboard
        .writeText(
          JSON.stringify({
            id: purchase.uuid,
            payPalId:
              purchase.type == "SUBSCRIPTION"
                ? purchase.payPalSubscriptionID
                : purchase.payPalTransactionId,
            cryptoCloud: purchase.cryptocloudTransactionId,
            type: purchase.type,
          })
        )
        .then(() => {
          toast.success("ID покупки скопировано успешно!");
        })
        .catch(() => {
          toast.error("Ошибка при копировании ID покупки.");
        });
    },

    issueUserPurchase(purchase) {
      if (purchase.type == "PRODUCT") {
        this.updateUserProduct({
          uuid: purchase.uuid,
          data: {
            status: "PAID",
            paymentType: "ISSUED",
          },
        });
      } else if (purchase.type == "SUBSCRIPTION") {
        this.updateUserSubscription({
          uuid: purchase.uuid,
          data: {
            status: "PAID",
            paymentType: "ISSUED",
          },
        });
      } else {
        toast.warn("Неизвестный тип покупки");
      }
    },

    rejectUserPurchase(purchase) {
      if (purchase.type == "PRODUCT") {
        this.updateUserProduct({
          uuid: purchase.uuid,
          data: {
            status: "REJECTED",
            paymentType: "ISSUED",
          },
        });
      } else if (purchase.type == "SUBSCRIPTION") {
        this.updateUserSubscription({
          uuid: purchase.uuid,
          data: {
            status: "REJECTED",
            paymentType: "ISSUED",
          },
        });
      } else {
        toast.warn("Неизвестный тип покупки");
      }
    },

    getPaymentStatusColorChip(status) {
      switch (status) {
        case "PENDING":
          return "yellow";
        case "REJECTED":
          return "red";
        case "ISSUED":
          return "blue";
        case "REFUNDED":
          return "blue";
        case "PAID":
          return "green";
        default:
          return "gray";
      }
    },

    selectSection(sectionName) {
      this.activeSection = sectionName;
    },

    selectRelease(uuid) {
      this.$router.push({ name: "Main", params: { release_uuid: uuid } });
    },

    copyReleaseURL(release) {
      navigator.clipboard.writeText(
        `${window.location.origin}/main/${release.uuid}`
      );
    },

    fetchUserReleasesRequest() {
      const payload = {
        userUuid: this.user.uuid,
        options: this.releasesPagination.options,
      };

      this.fetchUserReleases(payload).then((response) => {
        this.releases = response.data.data;
        this.releasesPagination.meta = response.data.meta;
      });
    },

    ban() {
      const ban_reason = prompt("Введите причину блокировки");
      const { credentials_uuid } = this.user.profile;
      if (!ban_reason || !credentials_uuid) return;

      this.banUser({
        credentials_uuid,
        ban_reason,
      }).then(() => {
        this.isBanning = false;
      });
    },

    unban() {
      const { credentials_uuid } = this.user.profile;
      if (!credentials_uuid) return;

      this.unbanUser(credentials_uuid);
    },

    getPaymentTypeName(id) {
      const paymentType = this.paymentTypesList.find((p) => p.id == id);
      const name = paymentType?.name;
      return name ?? id;
    },

    getProductAlias,
    getPaymentAlias,

    getFormattedDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("ru-RU", options);
      return formattedDate;
    },

    closeUserModal() {
      this.$emit("close");
    },

    scrollToEditBlock() {
      const element = this.$refs.editBlock;
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },

    scrollToTop() {
      const element = this.$refs.user;
      element.scrollTop = 0;
    },
  },
  watch: {
    selectedSubscriptionPurchaseName() {
      const today = new Date();
      const startDate = today;
      const endDate = new Date(startDate);

      const selectedSubscription = this.subscriptions.find(
        (subscription) =>
          subscription.name == this.selectedSubscriptionPurchaseName
      );

      const timeRange = selectedSubscription.timeRange;
      const timeRangeCount = selectedSubscription.timeRangeCount;

      switch (timeRange) {
        case "HOUR":
          endDate.setHours(endDate.getHours() + timeRangeCount);
          break;
        case "DAY":
          endDate.setDate(endDate.getDate() + timeRangeCount);
          break;
        case "YEAR":
          endDate.setFullYear(endDate.getFullYear() + timeRangeCount);
          break;
        default:
          endDate.setMonth(endDate.getMonth() + timeRangeCount);
          break;
      }

      this.subscriptionDateRange = [startDate, endDate];
    },
  },
  computed: {
    ...mapGetters([
      "file",
      "userPurchases",
      "products",
      "subscriptions",
      "usersPurchases",
    ]),

    canIssuePurchase() {
      return this.selectedPurchaseType && this.selectedPurchaseName;
    },

    selectedPurchaseName() {
      return this.selectedPurchaseType == "PRODUCT"
        ? getProductAlias(this.selectedProductPurchaseName)
        : this.selectedSubscriptionPurchaseName;
    },

    totalPurchaseCost() {
      if (this.selectedProductPurchaseName) {
        return this.products.find(
          (product) => product.name == this.selectedProductPurchaseName
        )?.amount;
      } else if (this.selectedSubscriptionPurchaseName) {
        return this.subscriptions.find(
          (subscription) =>
            subscription.name == this.selectedSubscriptionPurchaseName
        )?.amount;
      } else {
        return -1;
      }
    },

    subscriptionWithAlias() {
      return this.subscriptions.map((subscription) => ({
        id: subscription.name,
        name: subscription.name,
      }));
    },

    productWithAlias() {
      return this.products.map((product) => ({
        id: product.name,
        name: getProductAlias(product.name),
      }));
    },

    purchases() {
      return this.userPurchases(this.user.uuid).sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    },
    releasesList() {
      let releases = [...this.releases].map((release) => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString(
            "ru-RU"
          ),
          transfered_at: release.transfered_at
            ? new Date(release.transfered_at).toLocaleDateString("ru-RU")
            : null,
          artist: {
            name: "this.getArtist(release.artist_uuid) ? this.getArtist(release.artist_uuid).name : 'Artist'",
          },
          redirectUrl: `/drafts/${release.uuid}`,
        };
      });

      if (this.releasesPagination.options.status) {
        return releases.filter(
          (release) => release?.status == this.releasesPagination.options.status
        );
      } else {
        return releases;
      }
    },

    isUserBanned() {
      return this.user?.ban_reason !== null && this.user?.role == "banned";
    },

    userName() {
      const user = this.user;
      if (!user?.profile) return;

      const { first_name, last_name } = user?.profile;

      if (first_name || last_name) {
        return [first_name, last_name].join(" ");
      }
      return "Без имени";
    },

    minStartAt() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },

    maxExpiresAt() {
      const nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      return nextYear.toISOString().substr(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.add-purchase-modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .body {
    width: 500px;
    background: #000;
    padding: 20px;
    border-radius: 9px;
    border: 1px solid #0b0b0b;

    .add-purchase-block {
      display: flex;
      flex-direction: column;
    }

    .result {
      margin: 40px 0px;
      .title {
        margin: 10px 0px;
        color: #c2c3c3;
        span {
          color: white;
          margin-left: 5px;
        }
      }
    }

    .title {
      margin-bottom: 10px;
      margin-top: 15px;
    }

    .icon-title {
      margin-bottom: 10px;
      margin-top: 15px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .title--focus{
      color: #c2c3c3;
      margin: 10px 0px;
      animation: focus-title infinite 1s linear;
    }

    .actions {
      margin-top: 20px;
      display: flex;
      button {
        margin-right: 10px;
      }
    }
  }
}

@keyframes focus-title{
  from{
    color: #c2c3c3;
  }
  to{
    color: #8d8d8d;
  }
}

@keyframes expandAnimation {
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
}

@keyframes collapseAnimation {
  from {
    max-height: 100px;
  }
  to {
    max-height: 0;
  }
}

.expand-enter-active {
  animation: expandAnimation 0.4s forwards;
  overflow: hidden;
}
.expand-leave-active {
  overflow: hidden;
  animation: collapseAnimation 0.3s forwards;
}

.user {
  padding: 64px;
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 9px;
  background: #000;
  overflow-y: scroll;

  &__main {
    width: 100%;
  }

  &__releases {
    width: 100%;
    max-height: calc(100% - 200px);
    overflow-y: scroll;
  }

  &__purchases {
    width: 100%;
    height: calc(100% - 160px);
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 160px;

    .select-section-btn {
      margin-top: 20px;
    }
  }

  &__header {
  }
}
.main {
  display: flex;
  align-items: center;
  gap: 24px;

  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #d9d9d9;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: inherit;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__name {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__ban {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: $red;
      margin-bottom: 1px;
    }
  }

  &__copy {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    background: none;
    cursor: copy;
    border-radius: 4px;
    transition: 0.2s;

    svg {
      width: 21px;
      height: 21px;
      fill: #8d8d8d;
    }

    &:hover {
      transition: 0.2s;
      background: #454545;
    }
  }

  &__email {
    display: flex;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__verification {
    margin-left: -8px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;

  &__icon {
    width: 13px;
    height: 13px;

    &--verified {
      fill: #ed2224;
    }

    &--unverified {
      fill: #4e4e4e;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  min-height: 36px;

  &__title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }

  &__actions {
    display: flex;
    gap: 16px;
  }

  &__action {
  }
}
.confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__purchase {
  }

  &__dates {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__input {
    height: 40px;
  }
}

.user__purchases--table-wrapper {
  width: 100%;
  overflow-y: scroll;
  height: 100%;

  table {
    width: calc(100% - 25px);
    border-collapse: separate;
    border-spacing: 0 10px;
    table-layout: fixed;

    .actions {
      display: flex;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background: black;

      th {
        text-align: start;
        padding-inline: 15px;
      }
    }

    tbody {
      max-height: 300px; // Высота таблицы, можно настроить
      overflow-y: auto; // Скролл только по вертикали

      tr {
        background: #3f4146;
        width: 100%;
      }

      tr:nth-child(2n) {
        background: #454545;
      }

      td {
        padding: 5px 15px;
      }

      td:first-child {
        border-radius: 9px 0px 0px 9px;
      }

      td:last-child {
        border-radius: 0px 9px 9px 0px;
      }
    }
  }
}
</style>
