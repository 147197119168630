<template>
  <section class="releases">
    <div class="tools-wrapper">
      <Header>Релизы</Header>
      <div class="tools">
        <Search
          v-model="releaseSearchQuery"
          placeholder="Поиск"
          class="tools__wide tool"
        />
        <SelectButtons
          :items="statusFilters"
          v-model="pagination.options.status"
          @select="(item) => selectStatusFilter(item.value)"
        />
        <SelectButtons
          :items="transferedFilters"
          v-model="pagination.options.transfered"
          @select="(item) => selectTransferedFilter(item.value)"
        />
        <div class="stats tools__block" v-if="pagination.meta?.counts">
          <h2 class="releases__heading">
            Статистика
            <div class="stats__item">
              <div class="stats__title">всего:</div>
              <div class="stats__num">
                {{
                  pagination.meta.counts["TRANSFERED"] +
                  pagination.meta.counts["NOT_TRANSFERED"]
                }}
              </div>
            </div>
          </h2>
          <hr class="stats__hr" />
          <div class="stats__main">
            <div
              class="stats__col"
              v-for="statBlock in statistics"
              :key="statBlock.title"
            >
              <h3 class="stats__heading">
                {{ statBlock.title }}
              </h3>
              <div
                class="stats__item"
                v-for="statItem in statBlock.items"
                :key="statItem.key"
              >
                <div class="stats__title">
                  {{ statItem.title }}
                </div>
                <div class="stats__num">
                  {{ pagination.meta.counts[statItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="releases__loader" v-if="isLoadingReleases">
      <LoadingLogo />
    </div>
    <div class="releases-list" v-else>
      <Table>
        <table>
          <thead>
            <th>Обложка</th>
            <th>Название</th>
            <th>Пользователь</th>
            <th>Тип</th>
            <th>
              <button
                :class="[
                  'table-sortable',
                  { 'table-sortable-desc': pagination.options.order == 'DESC' },
                  { 'table-sortable-asc': pagination.options.order == 'ASC' },
                ]"
                @click="toggleSort()"
              >
                Дата
              </button>
            </th>
            <th v-show="!pagination.options.status">Статус</th>
            <th>Оплата</th>
            <th>Статус</th>
            <th>Действия</th>
          </thead>
          <tbody>
            <tr
              v-for="release in releasesList"
              :key="release.uuid"
              @click="selectRelease(release.uuid)"
            >
              <td>
                <div class="table-cover table-center">
                  <img
                    :src="
                      file(release.cover_uuid)?.base64 ??
                      require('@/assets/placeholder.png')
                    "
                    alt="Cover"
                  />
                </div>
              </td>
              <td>
                {{ release.title }}
              </td>
              <td>
                {{ getProfile(release.user_uuid)?.first_name ?? "Без имени" }}
              </td>
              <td>
                {{ release.type }}
              </td>
              <td>
                <div class="info__date">
                  <div>
                    {{ release.release_date }}
                  </div>
                  <div class="info__transfered" v-if="release.transfered_at">
                    <SvgIcon type="mdi" :path="icons.arrowRight" />
                    {{ release.transfered_at }}
                  </div>
                </div>
              </td>
              <td>
                <ReleasePurchaseStatusChip :releaseUuid="release.uuid" />
              </td>
              <td>
                <span class="info__status">
                  <StatusChip :status="release.status" />
                </span>
              </td>
              <td>
                <div class="table-actions" @click.stop>
                  <button
                    @click="downloadRelease(release)"
                    v-show="
                      !release.isDownloading ||
                      release.isDownloading == 'download'
                    "
                  >
                    <img src="@/assets/icons/download.svg" alt="" />
                  </button>
                  <button disabled v-show="release.isDownloading == 'loading'">
                    <LoadingLogo class="actions__icon" />
                  </button>
                  <button disabled v-show="release.isDownloading == 'error'">
                    <img
                      src="@/assets/icons/block.svg"
                      alt=""
                      class="actions__icon--slim"
                    />
                  </button>
                  <button @click="copyReleaseURL(release)">
                    <img
                      src="@/assets/icons/copy.svg"
                      alt=""
                      class="actions__icon"
                    />
                  </button>
                  <button
                    @click="startDeletingRelease(release)"
                    target="_blank"
                  >
                    <SvgIcon type="mdi" :path="icons.delete" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Table>

      <Pagination
        v-if="releasesList?.length"
        :options="pagination.options"
        :meta="pagination.meta"
        @options-change="
          (newOptions) => {
            pagination.options = newOptions;
          }
        "
      />
    </div>
  </section>

    <!-- SELECTED RELEASE MODAL-->
  <Transition name="scale">
    <Modal
      v-show="fetchingRelease || selectedRelease"
      @close="
        fetchingRelease = false;
        clearSelectedRelease();
      "
    >
      <LoadingLogo v-show="!selectedRelease" />
      <div class="release-view-wrapper">
        <div class="release-view" v-if="selectedRelease">
          <div class="preview">
            <div class="preview-wrapper">
              <div class="cover">
                <button @click="startEditCoverMode" class="edit-btn">
                  <img src="@/assets/icons/edit.svg" alt="" />
                </button>
                <img
                  :src="
                    this.file(this.selectedRelease.cover_uuid)?.base64 ??
                    require('@/assets/placeholder.png')
                  "
                  alt="Cover"
                />
              </div>
              <div class="title">
                <h2>{{ selectedRelease.title }}</h2>
                <StatusChip :status="selectedRelease.status" />
              </div>
            </div>
            <div class="actions">
              <button @click="clearSelectedRelease">
                <img src="@/assets/icons/close.svg" alt="" />
              </button>
              <button @click="copyReleaseURL(selectedRelease)">
                <img src="@/assets/icons/copy.svg" alt="" />
              </button>
              <button @click="startEditMode()">
                <img src="@/assets/icons/edit.svg" alt="" />
              </button>
              <button
                @click="downloadRelease(selectedRelease)"
                v-show="
                  !selectedRelease.isDownloading ||
                  selectedRelease.isDownloading == 'download'
                "
              >
                <img src="@/assets/icons/download.svg" alt="" />
              </button>
              <button
                disabled
                v-show="selectedRelease.isDownloading == 'loading'"
              >
                <LoadingLogo style="width: 25px; height: 25px" />
              </button>
              <button
                disabled
                v-show="selectedRelease.isDownloading == 'error'"
                style="opacity: 0.6"
              >
                <img src="@/assets/icons/block.svg" alt="" />
              </button>
              <button
                @click="startDeletingRelease(selectedRelease)"
                target="_blank"
              >
                <SvgIcon type="mdi" :path="icons.delete" />
              </button>
            </div>
          </div>
          <div class="info-wrapper">
            <h2>Основная информация</h2>
            <div class="info-items">
              <div class="info-item">
                <div class="info">
                  Тип релиза: <span>{{ selectedRelease.type }}</span>
                </div>
                <div class="info">
                  Количество треков:
                  <span>{{ selectedRelease.tracks.length }}</span>
                </div>
                <div class="info">
                  Жанр:
                  <span>{{
                    getPrimaryMusicStyle(selectedRelease.primary_music_style_id)
                      ? getPrimaryMusicStyle(
                          selectedRelease.primary_music_style_id
                        ).name
                      : "Неизвестен"
                  }}</span>
                </div>
                <div class="info">
                  Поджанр:
                  <span>{{
                    getPrimaryMusicStyle(
                      selectedRelease.secondary_music_style_id
                    )?.name || "Неизвестен"
                  }}</span>
                </div>
                <div class="info">
                  Язык:
                  <span>{{
                    getLanguage(selectedRelease.language_id)?.name ||
                    "Неизвестен"
                  }}</span>
                </div>
                <div class="info">
                  Title version:
                  <span>{{
                    selectedRelease.title_version
                      ? selectedRelease.title_version
                      : "Неизвестен"
                  }}</span>
                </div>
              </div>
              <div class="info-item">
                <div class="info" v-if="getProfile(selectedRelease.user_uuid)">
                  ФИО автора заявки:
                  <span
                    >{{
                      `${getProfile(selectedRelease.user_uuid).first_name} ${
                        getProfile(selectedRelease.user_uuid).last_name
                      }`
                    }}
                  </span>
                </div>
                <div class="info" v-if="getProfile(selectedRelease.user_uuid)">
                  Email автора заявка:
                  <span>{{
                    getUser(selectedRelease.user_uuid)?.email
                  }}</span>
                </div>
                <div class="info">
                  Copyright: <span>{{ selectedRelease.copyright }}</span>
                </div>
                <div class="info">
                  Дата публикации:
                  <span>{{
                    new Date(selectedRelease.release_date).toLocaleDateString(
                      "ru-RU"
                    )
                  }}</span>
                </div>
                <div class="info">
                  Дата создания:
                  <span>{{
                    new Date(selectedRelease.created_at).toLocaleDateString(
                      "ru-RU"
                    )
                  }}</span>
                </div>
                <div class="info">
                  Дата переноса:
                  <span>
                    {{
                      selectedRelease.transfered_at
                        ? new Date(
                            selectedRelease.transfered_at
                          ).toLocaleDateString("ru-RU")
                        : "Отсутствует"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <h2>Оплата</h2>
            <ReleasePurchaseInfo :releaseUuid="selectedRelease.uuid" />
          </div>
          <div class="info-wrapper">
            <h2>Артисты ({{ selectedRelease.artists.length }})</h2>
            <div v-if="selectedRelease.artists.length" class="artists">
              <Artist
                class="artist"
                v-for="artist in selectedRelease.artists"
                :key="artist.uuid"
                :artist="artist"
                @edit="openUpdateArtistModal"
                @info="openInfoArtistModal"
              />
            </div>
            <h4 v-else>У данного релиза нет артистов!</h4>
          </div>
          <div class="info-wrapper">
            <h2>Треки ({{ selectedRelease.tracks.length }})</h2>
            <div v-if="selectedRelease.tracks.length" class="tracks">
              <Track
                v-for="(track, index) in selectedRelease.tracks"
                :key="track.uuid"
                :track="track"
                :releaseUuid="selectedRelease.uuid"
                @play="() => onTrackPlay(index)"
              />
            </div>
            <h4 v-else>У данного релиза нет треков!</h4>
          </div>
          <div class="info-wrapper" v-if="selectedReleaseUser">
            <h2>Пользователь</h2>
            <Table>
              <table>
                <tr @click="selectUser(selectedReleaseUser)">
                  <td>
                    <Transition name="resize">
                      <div
                        class="table-cover table-center"
                        v-show="file(selectedReleaseUser?.avatar_url)?.base64"
                      >
                        <img
                          :src="file(selectedReleaseUser?.avatar_url)?.base64"
                          alt="Avatar"
                        />
                      </div>
                    </Transition>
                    <Transition name="resize">
                      <div
                        class="table-cover table-center"
                        v-show="!file(selectedReleaseUser?.avatar_url)?.base64"
                      >
                        <img
                          :src="require('@/assets/placeholder.png')"
                          alt="Avatar Placeholder"
                        />
                      </div>
                    </Transition>
                  </td>
                  <td>
                    {{ getUserName(selectedReleaseUser) }}
                  </td>
                  <td>
                    <Chip
                      :color="
                        selectedReleaseUser.verified_at ? 'green' : 'gray'
                      "
                    >
                      {{ selectedReleaseUser.email }}
                    </Chip>
                  </td>
                  <td>
                    {{ getFormattedDate(selectedReleaseUser.created_at) }}
                  </td>
                  <td>
                    <div class="table-actions" @click.stop>
                      <button @click="copyUserURL(selectedReleaseUser)">
                        <img src="@/assets/icons/copy.svg" alt="" />
                      </button>
                      <button @click="changeAuthor()">
                        <img src="@/assets/icons/transfer.svg" alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </Table>
          </div>
          <div class="actions" v-if="selectedRelease.status == 'PENDING'">
            <Button
              :disabled="actionLoading"
              class="accept"
              @click="moderateReleaseModal = true"
              blue
            >
              <span>Подтвердить корректность данных</span>
            </Button>
            <Button class="deny" gray @click="denyReleaseModal = true">
              Отклонить
            </Button>
          </div>
          <div class="actions" v-if="selectedRelease.status == 'MODERATED'">
            <Button
              :disabled="actionLoading"
              class="accept"
              @click="acceptReleaseModal = true"
            >
              <span>Привязать UPC</span>
            </Button>
            <Button class="deny" gray @click="denyReleaseModal = true">
              Отклонить
            </Button>
          </div>
          <Alert v-if="selectedRelease.status == 'PUBLISHED'" blue disabled>
            Данный релиз был успешно опубликован!
          </Alert>
          <Alert v-if="selectedRelease.status == 'DRAFT'" gray disabled>
            Это черновик.
          </Alert>
          <Alert
            v-if="selectedRelease.status == 'DENIED'"
            red
            disabled
            :message="selectedRelease.deny_reason"
            messageActiveText="Показать причину"
            messageInactiveText="Скрыть причину"
          >
            Этот релиз был отклонён.
          </Alert>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- END SELECTED RELEASE MODAL-->

  <!-- EDIT RELEASE MODAL -->
  <Transition name="scale">
    <Modal v-show="isEditMode" :zIndex="200" @close="isEditMode = false">
      <div class="modal modal--edit" v-if="selectedRelease">
        <h2>Редактирование релиза.</h2>
        <div style="height: 40px; margin: 5px 0px">
          <Transition name="scale">
            <Alert style="margin-bottom: 10px" v-show="releaseUPCAlreadyExist"
              >Такой UPC уже зарегистрирован.</Alert
            >
          </Transition>
        </div>
        <label class="label">
          <span class="title"> Обложка: </span>
          <FileInput
            @select="(image) => coverUploadHandler(image)"
            :type="'image'"
            class="form-item"
          />
        </label>
        <label class="label">
          <span class="title"> Тип релиза: </span>
          <Select
            class="form-item"
            :placeholder="'Выберите тип'"
            :items="getReleaseTypes.map((type) => ({ id: type, name: type }))"
            v-model="editReleaseData.type"
          />
        </label>
        <label class="label">
          <span class="title"> Название: </span>
          <Input
            v-model="editReleaseData.title"
            :placeholder="'release'"
            class="form-item"
          />
        </label>
        <label class="label">
          <span class="title"> Title version: </span>
          <Input
            v-model="editReleaseData.title_version"
            :placeholder="'remix'"
            class="form-item"
          />
        </label>
        <label class="label">
          <span class="title"> Copyright: </span>
          <Input
            v-model="editReleaseData.copyright"
            :placeholder="'MyLabel'"
            class="form-item"
          />
        </label>
        <label class="label">
          <span class="title"> Publisher: </span>
          <Input disabled :placeholder="'MyLabel'" class="form-item" />
        </label>
        <label class="label">
          <span class="title"> Жанр: </span>
          <Select
            class="form-item"
            :placeholder="'Выберите жанр'"
            :items="
              getPrimaryMusicStyles.map((style) => ({
                id: style.musicStyleId,
                name: style.name,
              }))
            "
            v-model="editReleaseData.primary_music_style_id"
          />
        </label>
        <label class="label">
          <span class="title"> Поджанр: </span>
          <Select
            class="form-item"
            :placeholder="'Выберите поджанр'"
            :items="
              getPrimaryMusicStyle(
                editReleaseData.primary_music_style_id
              )?.childs.map((style) => ({
                id: style.musicStyleId,
                name: style.name,
              }))
            "
            v-model="editReleaseData.secondary_music_style_id"
          />
        </label>
        <label class="label">
          <span class="title"> Язык: </span>
          <Select
            class="form-item"
            :placeholder="'Выберите язык'"
            :items="
              getLanguages.map((language) => ({
                id: language.language_id,
                name: language.name,
              }))
            "
            v-model="editReleaseData.language_id"
          />
        </label>
        <label class="label">
          <span class="title"> Дата выпуска: </span>
          <Input
            v-model="editReleaseData.release_date"
            :placeholder="'Release date'"
            type="date"
            class="form-item"
          />
        </label>
        <!-- <label class="label"> 
                <span class="title"> UPC: </span>
                <Input v-model="releaseUPC" :placeholder="'пример UPC: 1234567891234'" class="input" />
            </label> -->
        <div v-if="releaseSuccessUpdated || releaseErrorUpdated">
          <Alert blue v-if="releaseSuccessUpdated" style="margin-bottom: 10px">
            Релиз успешно обновлён
          </Alert>
          <Alert style="margin-bottom: 10px" v-else> Произошла ошибка! </Alert>
        </div>

        <div class="actions" v-else>
          <Button
            @click="updateReleaseRequest(this.editReleaseData)"
            :disabled="!editReleaseDataIsValid || releaseIsUpdating"
            blue
          >
            Обновить
          </Button>
          <Button
            @click="isEditMode = false"
            :disabled="releaseIsUpdating"
            gray
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- END EDIT RELEASE MODAL -->

  <!-- ACCEPT RELEASE MODAL -->
  <Transition name="scale">
    <Modal v-show="acceptReleaseModal" :zIndex="200" @close="closeAcceptModal">
      <div class="modal" v-if="selectedRelease">
        <Alert v-show="releaseUPCAlreadyExist"
          >Данный UPC принадлежит другому релизу!</Alert
        >
        <h2>
          Пожалуйста введите UPC данного релиза и подтвердите свои действия!
        </h2>
        <p>Введите UPC релиза</p>
        <Input
          :placeholder="'пример UPC: 1234567891234'"
          class="input"
          v-model="releaseUPC"
        />
        <p>
          Для подтверждения введите <br />
          <span>"{{ selectedRelease.title }}"</span>
        </p>
        <Input
          :placeholder="selectedRelease.title"
          class="input"
          v-model="foolProofing"
        />
        <div class="actions">
          <Button
            :loading="actionLoading"
            :disabled="
              actionLoading ||
              selectedRelease.title != foolProofing ||
              !validReleaseUPC ||
              releaseUPCAlreadyExist
            "
            @click="acceptReleaseRequest"
          >
            Подтвердить публикацию
          </Button>
          <Button gray @click="closeAcceptModal"> Отмена </Button>
        </div>
        <Alert style="margin: 10px 0px" v-show="isAcceptReleaseError">
          При публикации произошла ошибка</Alert
        >
      </div>
    </Modal>
  </Transition>
  <!-- END ACCEPT RELEASE MODAL -->

  <!-- MODERATE RELEASE MODAL -->
  <Transition name="scale">
    <Modal
      v-show="moderateReleaseModal"
      :zIndex="200"
      @close="moderateReleaseModal = false"
    >
      <div class="modal" v-if="selectedRelease">
        <h2>
          Вы подтверждаете что все данные релиза были заполнены корректно?
        </h2>
        <div class="actions">
          <Button
            :loading="actionLoading"
            blue
            @click="moderateReleaseRequest(selectedRelease.uuid)"
          >
            Да, все данные корректны
          </Button>
          <Button gray @click="moderateReleaseModal = false"> Нет </Button>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- END ACCEPT RELEASE MODAL -->

  <!-- DENY RELEASE MODAL -->
  <Transition name="scale">
    <Modal
      v-show="denyReleaseModal"
      :zIndex="200"
      @close="
        denyReleaseModal = false;
        denyReason = '';
      "
    >
      <div class="modal" v-if="selectedRelease">
        <h2>Пожалуйста укажите причину отказа.</h2>
        <Textarea
          :placeholder="'причина...'"
          class="input"
          v-model="denyReason"
          :maxHeight="300"
        />
        <!-- <Input :placeholder="'причина...'" class="input" v-model="denyReason" /> -->
        <div class="actions">
          <Button
            :disabled="denyReason.length < 3"
            @click="denyReleaseRequest(selectedRelease.uuid)"
          >
            Отклонить
          </Button>
          <Button
            gray
            @click="
              denyReleaseModal = false;
              denyReason = '';
            "
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- DENY RELEASE MODAL -->

  <!-- DELETE RELEASE MODAL -->
  <Transition name="scale">
    <Modal
      v-show="deletingRelease"
      :zIndex="200"
      @close="cancelDeletingRelease"
    >
      <div class="modal" v-if="deletingRelease">
        <h2>
          Вы уверены, что хотите удалить релиз {{ deletingRelease.title }}?
        </h2>
        <div class="actions">
          <Button @click="deleteReleaseRequest(deletingRelease.uuid)">
            <LoadingLogo class="loader" v-if="isDeleteReleaseInProgress" />
            <span v-else> Удалить </span>
          </Button>
          <Button gray @click="cancelDeletingRelease"> Отмена </Button>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- DELETE RELEASE MODAL -->

  <Transition>
    <Modal
      v-show="showReleaseAcceptSuccessDialog"
      :zIndex="201"
      @close="showReleaseAcceptSuccessDialog = false"
    >
      <div class="modal">
        <h2>Релиз успешно опубликован!</h2>
        <div class="actions">
          <Button @click="showReleaseAcceptSuccessDialog = false"> Ок </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <Transition>
    <Modal
      v-show="showReleaseModeratedSuccessDialog"
      :zIndex="201"
      @close="showReleaseModeratedSuccessDialog = false"
    >
      <div class="modal">
        <h2>
          Спасибо! Пользователь будет уведомлён. Не забудьте отправить релиз на
          площадки.
        </h2>
        <div class="actions">
          <Button @click="showReleaseModeratedSuccessDialog = false">
            Ок
          </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <Transition>
    <Modal
      v-show="showReleaseDenySuccessDialog"
      :zIndex="201"
      @close="showReleaseDenySuccessDialog = false"
    >
      <div class="modal">
        <h2>Релиз успешно отклонён!</h2>
        <div class="actions">
          <Button @click="showReleaseDenySuccessDialog = false"> Ок </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <Transition>
    <Modal
      v-show="errorReleaseDenyDialog"
      :zIndex="201"
      @close="errorReleaseDenyDialog = false"
    >
      <div class="modal">
        <h2>Произошла ошибка, релиз не отклонён.</h2>
        <div class="actions">
          <Button @click="errorReleaseDenyDialog = false"> Ок </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <Transition>
    <Modal
      v-show="errorReleaseModerateDialog"
      :zIndex="201"
      @close="errorReleaseModerateDialog = false"
    >
      <div class="modal">
        <h2>При проверке данных произошла ошибка.</h2>
        <div class="actions">
          <Button @click="errorReleaseModerateDialog = false"> Ок </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <!-- COVER CROPPER MODAL-->
  <Transition name="scale">
    <Modal
      v-if="isEditCoverMode && croppingImageSrc.length"
      :zIndex="200"
      @close="isEditCoverMode = false"
    >
      <CoverCropper @save="coverCropHandler" :src="croppingImageSrc" />
    </Modal>
  </Transition>
  <!-- END COVER CROPPER MODAL -->

  <Transition name="scale">
    <Modal
      :zIndex="300"
      v-if="isArtistLoading || selectedArtist"
      @close="closeArtistModalWrapper"
    >
      <LoadingLogo v-show="isArtistLoading" />
      <UpdateArtistModal
        v-if="selectedArtist && isOpenEditAritstModal"
        :artist="selectedArtist"
        @close="closeUpdateArtistModal"
      />
      <InfoArtistModal
        v-if="selectedArtist && isOpenInfoAritstModal"
        :artist="selectedArtist"
        @close="closeInfoArtistModal"
      />
    </Modal>
  </Transition>

  <!-- USER MODAL -->
  <Transition name="fade">
    <UserModal
      v-if="isUserModalVisible && selectedUser"
      :user="selectedUser"
      @close="closeUserModal"
    />
  </Transition>
  <!-- END USER MODAL -->

  <!-- CHANGE RELEASE AUTHOR MODAL -->
  <Transition name="scale">
    <Modal
      v-if="isChangeReleaseAuthor"
      :zIndex="200"
      @close="isChangeReleaseAuthor = false"
    >
      <div class="modal" style="width: 900px">
        <h2>Выберите нового автора релиза</h2>
        <Input
          style="width: 100%"
          v-model="searchQuery"
          :placeholder="'Введите email для поиска'"
        />
        <Table>
          <table>
            <thead>
              <th>Фото</th>
              <th>Имя</th>
              <th>Почта</th>
            </thead>
            <tbody>
              <h3
                style="white-space:nowrap:"
                v-if="!searchUserLoading && !filteredUsers.length"
              >
                Пользователей не найдено
              </h3>
              <tr
                class="loading"
                v-if="searchUserLoading && !filteredUsers.length"
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                v-for="user in filteredUsers"
                :key="user.uuid"
                @click="selectNewAuthor(user)"
              >
                <td>
                  <div
                    v-if="user.avatar_is_loading"
                    class="table-cover-loading table-center"
                  ></div>
                  <Transition name="resize">
                    <div
                      class="table-cover table-center"
                      v-show="
                        file(user.profile?.avatar_url)?.base64 &&
                        !user.avatar_is_loading
                      "
                    >
                      <img
                        :src="file(user.profile?.avatar_url)?.base64"
                        alt="Avatar"
                      />
                    </div>
                  </Transition>
                  <Transition name="resize">
                    <div
                      class="table-cover table-center"
                      v-show="
                        !file(user.profile?.avatar_url)?.base64 &&
                        !user.avatar_is_loading
                      "
                    >
                      <img
                        :src="require('@/assets/placeholder.png')"
                        alt="Avatar Placeholder"
                      />
                    </div>
                  </Transition>
                </td>
                <td>
                  {{ getUserName(user) }}
                </td>
                <td>
                  <Chip :color="user.verified_at ? 'green' : 'gray'">
                    {{ user.email }}
                  </Chip>
                </td>
                <td>
                  <img
                    v-if="newAuthor && user.email == newAuthor.email"
                    style="width: 20px; height: 20px"
                    src="@/assets/icons/success.svg"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Table>
        <Pagination
          v-if="!searchQuery"
          :options="usersPagination.options"
          :meta="usersPagination.meta"
          @options-change="
            (newOptions) => {
              usersPagination.options = newOptions;
            }
          "
        />
        <div class="actions" style="margin-top: 20px">
          <Button @click="confirmChangeAuthor()" :disabled="!newAuthor">
            Перенести релиз
          </Button>
          <Button gray @click="closeChangeAuthorModal"> Отмена </Button>
        </div>
      </div>
    </Modal>
  </Transition>
  <!-- END CHANGE RELEASE AUTHOR MODAL -->

  <Transition name="scale">
    <Modal
      v-if="isConfirmChangeReleaseAuthor"
      :zIndex="200"
      @close="isConfirmChangeReleaseAuthor = false"
    >
      <div class="modal">
        <h2>Подтвердите правильность пользователя</h2>
        <p>
          релиз: <br />
          <span>"{{ selectedRelease.title }}"</span>
        </p>
        <p>
          пользователь:<br />
          <span>"{{ newAuthor.email }}"</span>
        </p>
        <div class="actions" style="margin-top: 20px">
          <Button
            @click="changeReleaseAuthorRequest"
            :loading="isChangeReleaseAuthorLoading"
            :disabled="isChangeReleaseAuthorLoading"
          >
            Да всё верно
          </Button>
          <Button
            gray
            @click="closeChangeAuthorModal"
            v-show="!isChangeReleaseAuthorLoading"
          >
            Отмена
          </Button>
        </div>

        <Alert style="margin: 10px 0px" v-show="isChangeReleaseAuthorError">
          При смене автора релиза произошла ошибка</Alert
        >
      </div>
    </Modal>
  </Transition>
  <Transition name="scale">
    <Modal
      v-if="isChangeReleaseAuthorSuccess"
      :zIndex="200"
      @close="isChangeReleaseAuthorSuccess = false"
    >
      <div class="modal">
        <h2>Релиз "{{ selectedRelease.title }}" успешно перенесён</h2>
        <div class="actions" style="margin-top: 20px">
          <Button @click="closeChangeAuthorModal"> Ок </Button>
        </div>
      </div>
    </Modal>
  </Transition>

  <FileInput
    ref="coverInput"
    @select="(image) => updateCover(image)"
    :type="'image'"
    style="display: none"
  />
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Search from "@/components/Search.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import Button from "@/components/Button.vue";
import LoadingLogo from "@/components/LoadingLogo.vue";
import Alert from "@/components/Alert.vue";
import Input from "@/components/Input.vue";
import Track from "@/components/Track.vue";
import Artist from "@/components/Artist.vue";
import Select from "@/components/Select.vue";
import CoverCropper from "@/components/CoverCropper.vue";
import FileInput from "@/components/FileInput.vue";
import StatusChip from "@/components/StatusChip.vue";
import Chip from "@/components/Chip.vue";
import SelectButtons from "@/components/SelectButtons.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import axios from "../axios";
import { mdiArrowRight, mdiDelete } from "@mdi/js";
import ReleaseStatus from "@/utils/ReleaseStatus";
import Table from "../components/Table.vue";
import UserModal from "../components/UserModal.vue";
import getUserName from "@/utils/getUserName";
import getFormattedDate from "@/utils/getFormattedDate";
import UpdateArtistModal from "@/components/UpdateArtistModal.vue";
import InfoArtistModal from "@/components/InfoArtistModal.vue";
import Textarea from "@/components/Textarea.vue";
import ReleasePurchaseStatusChip from '@/components/payments/ReleasePurchaseStatusChip.vue';
import ReleasePurchaseInfo from '@/components/payments/ReleasePurchaseInfo.vue'

export default {
  components: {
    Header,
    Search,
    Modal,
    Pagination,
    Button,
    LoadingLogo,
    Alert,
    Input,
    Track,
    Artist,
    CoverCropper,
    Select,
    FileInput,
    StatusChip,
    Chip,
    SelectButtons,
    SvgIcon,
    Table,
    UserModal,
    Textarea,
    UpdateArtistModal,
    InfoArtistModal,
    ReleasePurchaseStatusChip,
    ReleasePurchaseInfo
  },

  data() {
    return {
      ReleaseStatus,

      usersPagination: {
        options: {
          page: 1,
          take: 20,
          role: null,
          verified: null,
          order: "DESC",
        },
        meta: {},
      },

      icons: {
        arrowRight: mdiArrowRight,
        delete: mdiDelete,
      },

      pagination: {
        options: {
          page: 1,
          take: 10,
          status: "PENDING",
          search: undefined,
          transfered: undefined,
          order: "DESC",
        },
        meta: {},
      },

      transferedFilters: [
        {
          key: "ALL",
          value: undefined,
          name: "все",
        },
        {
          key: "TRANSFERED",
          value: 1,
          name: "перенесены",
        },
        {
          key: "NOT_TRANSFERED",
          value: 0,
          name: "созданы",
        },
      ],
      statusFilters: [
        {
          key: "ALL",
          value: undefined,
          name: "все",
        },
        {
          key: "PENDING",
          value: "PENDING",
          name: "новые",
        },
        {
          key: "MODERATED",
          value: "MODERATED",
          name: "в ожидании",
        },
        {
          key: "PUBLISHED",
          value: "PUBLISHED",
          name: "отправленные",
        },
        {
          key: "DENIED",
          value: "DENIED",
          name: "отклоненные",
        },
      ],

      statistics: [
        {
          title: "По статусу",
          items: [
            {
              key: "MODERATED",
              title: "Новых",
            },
            {
              key: "PENDING",
              title: "В ожидании",
            },
            {
              key: "PUBLISHED",
              title: "Опубликованных",
            },
            {
              key: "DENIED",
              title: "Отклоненных",
            },
            {
              key: "DRAFT",
              title: "Черновиков",
            },
          ],
        },
        {
          title: "Трансерфинг",
          items: [
            {
              key: "TRANSFERED",
              title: "Перенесено",
            },
            {
              key: "NOT_TRANSFERED",
              title: "Создано в н.к",
            },
          ],
        },
      ],

      selectedArtist: null,
      isArtistLoading: false,
      isOpenEditAritstModal: false,
      isOpenInfoAritstModal: false,

      foolProofing: "",
      releaseSearchQueryTimeoutPointer: null,
      releaseSearchQuery: "",
      searchQuery: "",
      newAuthor: null,

      isLoadingReleases: false,
      denyReason: "",
      deletingRelease: null,
      isDeleteReleaseInProgress: false,

      //release-view
      fetchingRelease: false,
      selectedRelease: null,
      releaseUPC: "",
      actionLoading: false,
      acceptReleaseModal: false,
      moderateReleaseModal: false,
      denyReleaseModal: false,

      showReleaseAcceptSuccessDialog: false,
      showReleaseModeratedSuccessDialog: false,
      showReleaseDenySuccessDialog: false,
      errorReleaseModerateDialog: false,
      errorReleaseDenyDialog: false,
      isAcceptReleaseError: "",
      releaseUPCAlreadyExist: false,

      releaseIsUpdating: false,
      releaseSuccessUpdated: false,
      releaseErrorUpdated: false,
      isEditMode: false,
      isEditCoverMode: false,
      editReleaseData: {},
      croppingImageSrc: "",
      paginationParamsParsed: false,
      isChangeReleaseAuthor: false,
      isConfirmChangeReleaseAuthor: false,
      isChangeReleaseAuthorSuccess: false,
      isChangeReleaseAuthorError: false,
      isChangeReleaseAuthorLoading: false,

      // user modal
      isUserModalVisible: false,
      selectedUser: null,

      searchUserLoading: false,
      searchUserPointer: false,
      searchedUser: [],
    };
  },

  async mounted() {
    this.fetchAllArtists();
    this.fetchReleasesRequest();

    if (this.$route.params.release_uuid)
      this.selectRelease(this.$route.params.release_uuid);

    this.fetchMusicStyles();
    this.fetchLanguages();
    this.downloadImages();
    this.parsePaginationOptions();
    this.fetchAllUsersRequest();
  },

  computed: {
    ...mapGetters([
      "getReleases",
      "getArtist",
      "getProfile",
      "getCredential",
      "getAllProfiles",
      "getPrimaryMusicStyles",
      "getPrimaryMusicStyle",
      "getSecondaryMusicStyles",
      "getSecondaryMusicStyle",
      "getLanguage",
      "getLanguages",
      "getReleaseTypes",
      "file",
      "users",
      "getUser",
    ]),

    filteredUsers() {
      if (this.searchQuery) {
        return this.searchedUser;
      } else {
        return this.users;
      }
    },

    selectedReleaseUser() {
      if (!this.selectedRelease) return null;

      const credential =
        this.getCredential(this.selectedRelease.user_uuid) || {};
      const profile = this.getProfile(this.selectedRelease.user_uuid);

      return {
        ...credential,
        profile,
      };
    },

    releasesList: function () {
      let releases = [...this.getReleases].map((release) => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString(
            "ru-RU"
          ),
          transfered_at: release.transfered_at
            ? new Date(release.transfered_at).toLocaleDateString("ru-RU")
            : null,
          artist: {
            name: "this.getArtist(release.artist_uuid) ? this.getArtist(release.artist_uuid).name : 'Artist'",
          },
          redirectUrl: `/drafts/${release.uuid}`,
        };
      });

      if (this.pagination.options.status) {
        return releases.filter(
          (release) => release?.status == this.pagination.options.status
        );
      } else {
        return releases;
      }
    },

    validReleaseUPC: function () {
      const RegexUPC = /^\d{13,}$/;
      return RegexUPC.test(this.releaseUPC);
    },

    editReleaseDataIsValid: function () {
      return (
        JSON.stringify(this.selectedRelease) !=
          JSON.stringify(this.editReleaseData) &&
        // this.validReleaseUPC &&
        this.editReleaseData?.title?.length > 2 &&
        this.editReleaseData?.title_version?.length > 2 &&
        this.editReleaseData?.copyright?.length > 2
      );
      // && !this.releaseUPCAlreadyExist
    },
  },

  methods: {
    ...mapActions([
      "fetchAllReleases",
      "fetchRelease",
      "acceptRelease",
      "moderateRelease",
      "denyRelease",
      "updateRelease",
      "fetchProfileByCredential",
      "fetchCredential",
      "fetchFile",
      "fetchAllArtists",
      "fetchMusicStyles",
      "fetchLanguages",
      "fetchUserPurchases",
      "uploadFile",
      "deleteRelease",
      "fetchUsers",
      "changeReleaseAuthor",
      "fetchArtist",
      "searchUser",
      "fetchUser"
    ]),

    ...mapMutations([
      "setQueue",
      "setCurrentTrackIndex",
      "setCoverUuid",
      "updateReleaseDownloadStatus",
      "setUser",
      "setUsers",
    ]),

    async closeArtistModalWrapper() {
      this.selectedArtist = null;
    },

    async openUpdateArtistModal(artistUuid) {
      await this.selectArtist(artistUuid);
      this.isOpenEditAritstModal = true;
    },

    closeUpdateArtistModal() {
      this.isOpenEditAritstModal = false;
      this.closeArtistModalWrapper();
    },

    async openInfoArtistModal(artistUuid) {
      await this.selectArtist(artistUuid);
      this.isOpenInfoAritstModal = true;
    },

    closeInfoArtistModal() {
      this.isOpenInfoAritstModal = false;
      this.closeArtistModalWrapper();
    },

    selectArtist(artistUuid) {
      this.isArtistLoading = true;
      return new Promise((resolve, reject) => {
        this.fetchArtist(artistUuid)
          .then((response) => {
            console.log("artist", response);
            this.selectedArtist = response.data;
            resolve(this.selectedArtist);
          })
          .catch((e) => reject(e))
          .finally(() => {
            this.isArtistLoading = false;
          });
      });
    },

    closeChangeAuthorModal() {
      (this.newAuthor = false),
        (this.isConfirmChangeReleaseAuthor = false),
        (this.isChangeReleaseAuthor = false),
        (this.isChangeReleaseAuthorSuccess = false);
      this.isChangeReleaseAuthorError = false;
      this.isChangeReleaseAuthorLoading = false;
    },

    confirmChangeAuthor() {
      this.isChangeReleaseAuthor = false;
      this.isConfirmChangeReleaseAuthor = true;
    },

    changeReleaseAuthorRequest() {
      this.isChangeReleaseAuthorSuccess = false;
      this.isChangeReleaseAuthorError = false;
      this.isChangeReleaseAuthorLoading = true;
      this.changeReleaseAuthor({
        releaseUuid: this.selectedRelease.uuid,
        userUuid: this.newAuthor.uuid,
      })
        .then(() => {
          this.isChangeReleaseAuthorSuccess = true;
        })
        .catch(() => {
          this.isChangeReleaseAuthorError = true;
        })
        .finally(() => {
          this.isChangeReleaseAuthorLoading = false;
        });
    },

    selectNewAuthor(user) {
      this.newAuthor = user;
    },

    async fetchAllUsersRequest() {
      const payload = this.usersPagination.options;
      let users = [];

      this.fetchUsers(payload)
        .then((response) => {
          this.usersPagination.meta = response.data.meta;
          users = response.data.data;

          this.fetchUserAvatars(users);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async fetchUserAvatars(users) {
      for (const user of users) {
        user.avatar_is_loading = true;
        await this.fetchFile(user.profile?.avatar_url);
        user.avatar_is_loading = false;
        this.setUser(user);
        console.log(user.avatar_is_loading);
      }
    },

    getUserName,
    getFormattedDate,

    changeAuthor() {
      this.isChangeReleaseAuthor = true;
    },

    selectUser(user) {
      this.$router.push({ name: "Users", params: { user_uuid: user.uuid } });
      // this.selectedUser = user;
      // this.isUserModalVisible = true;
      // this.fetchUserPurchases(user.uuid);
    },

    closeUserModal() {
      this.isUserModalVisible = false;
      this.selectedUser = null;
    },

    closeAcceptModal() {
      this.acceptReleaseModal = false;
      this.releaseUPC = "";
      this.foolProofing = "";
    },

    parsePaginationOptions() {
      let queryString = window.location.search;
      let queryParams = {};

      if (queryString.charAt(0) === "?") {
        queryString = queryString.slice(1);
      }

      let paramsArray = queryString.split("&");

      paramsArray.forEach((param) => {
        let [key, value] = param.split("=");
        queryParams[key] = decodeURIComponent(value);
      });

      // Перебираем исходные параметры и обновляем их значения, если они присутствуют в URL-адресе
      for (let key in this.pagination.options) {
        if (key in queryParams) {
          this.pagination.options[key] = queryParams[key];
        }
      }

      this.paginationParamsParsed = true;

      console.log(this.pagination.options);
    },

    startDeletingRelease(release) {
      this.deletingRelease = release;
    },

    cancelDeletingRelease() {
      this.deletingRelease = null;
    },

    deleteReleaseRequest(uuid) {
      this.isDeleteReleaseInProgress = true;
      this.deleteRelease(uuid).then(() => {
        this.isDeleteReleaseInProgress = false;
        this.deletingRelease = null;
        this.selectedRelease = null;
      });
    },

    toggleSort() {
      if (this.pagination.options.order == "ASC") {
        this.pagination.options.order = "DESC";
      } else {
        this.pagination.options.order = "ASC";
      }
    },

    async downloadRelease(release) {
      console.log(release);

      const confirmDownload = confirm("Вы уверены, что хотите загрузить файл?");

      if (confirmDownload) {
        release.isDownloading = "loading";
        this.updateReleaseDownloadStatus({
          uuid: release.uuid,
          status: "loading",
        });

        try {
          const response = await axios.get(`/release-info/${release.uuid}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            responseType: "blob", // Установите тип ответа как blob, если вы ожидаете скачиваемый файл
          });

          const fileName = `${release.title}.zip`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", fileName); // Укажите имя файла
          document.body.appendChild(link);
          link.click();

          release.isDownloading = "download";
          this.updateReleaseDownloadStatus({
            uuid: release.uuid,
            status: "download",
          });
        } catch (error) {
          release.isDownloading = "error";
          this.updateReleaseDownloadStatus({
            uuid: release.uuid,
            status: "error",
          });
          console.error("Ошибка при загрузке релиза:", error);
        }
      }
    },

    fetchReleasesRequest() {
      this.isLoadingReleases = true;
      this.fetchAllReleases(this.pagination.options)
        .then((response) => {
          this.pagination.meta = response.data.meta;
          this.fetchReleasesCovers();
          this.fetchUsersRequest();
        })
        .finally(() => {
          this.isLoadingReleases = false;
        });
    },

    fetchUsersRequest() {
      const uniqueUserUuids = new Set(
        this.getReleases.map((release) => release.user_uuid)
      );

      uniqueUserUuids.forEach((userUuid) => {
        this.fetchProfileByCredential(userUuid);
      });
    },

    fetchReleasesCovers() {
      this.getReleases.forEach((release) => {
        const { cover_uuid } = release;
        const file = this.file(cover_uuid);
        if (!file) {
          this.fetchFile(cover_uuid);
        }
      });
    },

    saveStatusFilterToLocalStorage(statusValue) {
      localStorage.setItem("releaseStatusFilter", statusValue);
    },

    selectStatusFilter(statusValue) {
      this.pagination.options = {
        ...this.pagination.options,
        page: 1,
        status: statusValue,
      };
      this.saveStatusFilterToLocalStorage(statusValue);
    },

    selectTransferedFilter(transferedValue) {
      this.pagination.options = {
        ...this.pagination.options,
        page: 1,
        transfered: transferedValue,
      };
    },

    uploadFileRequest(file, loadProgress) {
      return this.uploadFile({ file, loadProgress });
    },

    async coverCropHandler({ binary }) {
      this.isEditCoverMode = false;

      const fileUuid = await new Promise((resolve, reject) => {
        this.uploadFileRequest(binary, () => {})
          .then((response) => {
            this.editReleaseData.cover_uuid = response.data.uuid;
            resolve(this.editReleaseData.cover_uuid);
          })
          .catch((e) => reject(e));
      });

      if (this.isEditMode) return;
      const release = {
        ...this.selectedRelease,
        cover_uuid: fileUuid,
      };
      this.updateRelease(release).then(() => {
        // todo: убрать then когда patch будет возвращать обновленную сущность
        this.selectedRelease.cover_uuid = fileUuid;
      });
    },

    coverUploadHandler({ base64 }) {
      this.croppingImageSrc = base64;
      this.isEditCoverMode = true;
    },

    updateReleaseRequest(release) {
      this.releaseIsUpdating = true;
      this.updateRelease(release)
        .then((response) => {
          this.selectedRelease = response.data;
          this.releaseSuccessUpdated = true;
        })
        .catch((e) => {
          this.releaseErrorUpdated = true;
          console.error(e);
        })
        .finally(() => {
          this.releaseIsUpdating = false;
          setTimeout(() => {
            this.isEditMode = false;
          }, 2000);
        });
    },

    updateCover({ base64 }) {
      this.croppingImageSrc = base64;
      this.isEditCoverMode = true;
    },

    onTrackPlay(trackIndex) {
      this.setCurrentTrackIndex(trackIndex);
    },

    startEditMode() {
      this.editReleaseData = { ...this.selectedRelease };
      this.isEditMode = true;
      this.releaseSuccessUpdated = false;
      this.releaseErrorUpdated = false;
    },

    startEditCoverMode() {
      this.$refs.coverInput.browseFiles();
    },

    copyReleaseURL(release) {
      navigator.clipboard.writeText(
        `${window.location.origin}/main/${release.uuid}`
      );
    },

    copyUserURL(user) {
      navigator.clipboard.writeText(
        `${window.location.origin}/users/${user.uuid}`
      );
    },

    selectRelease(uuid) {
      console.log("select release");
      this.fetchingRelease = true;
      this.$router.push({ name: "Main", params: { release_uuid: uuid } });

      this.fetchRelease(uuid)
        .then((response) => {
          this.selectedRelease = response.data;
          console.log("selectedRelease", this.selectedRelease);
          this.setQueue(this.selectedRelease.tracks);
          this.setCoverUuid(this.selectedRelease.cover_uuid);
          this.fetchSelectedReleaseTracks();
          this.fetchSelectedReleaseUser();
          this.fetchFile(this.selectedRelease.cover_uuid);
          this.fetchUser(this.selectedRelease.user_uuid)
        })
        .finally(() => {
          this.fetchingRelease = false;
        });
    },

    fetchSelectedReleaseUser() {
      this.fetchCredential(this.selectedRelease.user_uuid);
      this.fetchProfileByCredential(this.selectedRelease.user_uuid);
    },

    async fetchSelectedReleaseTracks() {
      for (const track of this.selectedRelease.tracks) {
        const fileUuid = track.wav_uuid;
        await this.fetchFile(fileUuid);
      }
    },

    clearSelectedRelease() {
      this.selectedRelease = null;
      this.$router.push({ name: "Main", params: { release_uuid: "" } });
    },

    closeReleaseAcceptModal() {
      this.acceptReleaseModal = false;
      this.releaseUPC = "";
      this.isAcceptReleaseError = "";
      this.actionLoading = false;
      this.selectedRelease = null;
      this.foolProofing = "";
    },

    acceptReleaseRequest() {
      this.actionLoading = true;
      this.acceptRelease({
        release_uuid: this.selectedRelease.uuid,
        release_upc: this.releaseUPC,
      })
        .then((release) => {
          this.closeReleaseAcceptModal();
          this.selectedRelease = release;
          this.showReleaseAcceptSuccessDialog = true;
        })
        .catch((error) => {
          console.error(error);
          this.isAcceptReleaseError = error;
        })
        .finally(() => {
          this.closeAcceptModal();
          this.actionLoading = false;
          this.selectedRelease = null;
        });
    },

    denyReleaseRequest(uuid) {
      this.actionLoading = true;
      this.denyRelease({
        uuid,
        deny_reason: this.denyReason,
      })
        .then((release) => {
          this.selectedRelease = release;
          this.actionLoading = false;
          this.showReleaseDenySuccessDialog = true;
        })
        .catch(() => {
          this.errorReleaseDenyDialog = true;
        })
        .finally(() => {
          this.denyReleaseModal = false;
          this.denyReason = "";
        });
    },

    moderateReleaseRequest(uuid) {
      this.actionLoading = true;
      this.moderateRelease({ uuid })
        .then((release) => {
          this.selectedRelease = release;
          this.showReleaseModeratedSuccessDialog = true;
        })
        .catch(() => {
          this.errorReleaseModerateDialog = true;
        })
        .finally(() => {
          this.moderateReleaseModal = false;
          this.actionLoading = false;
        });
    },

    getAllImageUuids() {
      return new Set(
        [...this.getReleases].map((release) => release.cover_uuid)
      );
    },

    getFieldByNameFromObject(fullFieldName, object) {
      const fieldNames = fullFieldName.split(".");

      let value = object[fieldNames[0]];

      if (fieldNames.length > 1) {
        for (let i = 1; i < fieldNames.length; i++) {
          value = value[fieldNames[i]];
        }
      }

      return value;
    },

    downloadImages() {
      const imageUuids = this.getAllImageUuids();
      imageUuids.forEach((uuid) => {
        this.fetchFile(uuid);
      });
    },
  },

  watch: {
    searchQuery() {
      this.searchUserLoading = true;
      window.clearTimeout(this.searchUserPointer);
      this.searchUserPointer = null;
      this.searchedUser = [];
      this.searchUserPointer = setTimeout(() => {
        this.searchUser(this.searchQuery)
          .then((response) => {
            this.searchedUser = [response.data];
          })
          .finally(() => {
            this.searchUserLoading = false;
          });
      }, 500);
    },
    releaseSearchQuery() {
      window.clearTimeout(this.releaseSearchQueryTimeoutPointer);
      this.releaseSearchQueryTimeoutPointer = setTimeout(() => {
        this.pagination.options.search = this.releaseSearchQuery;
      }, 500);
    },
    releaseUPC() {
      axios
        .get(`release-upc-exist/${this.releaseUPC}`)
        .then(() => {
          this.releaseUPCAlreadyExist = true;
        })
        .catch(() => {
          this.releaseUPCAlreadyExist = false;
        });
    },
    "pagination.options": {
      deep: true,
      immediate: true,
      handler() {
        if (this.paginationParamsParsed) {
          let currentUrl = window.location.origin + window.location.pathname;

          let query_params = Object.keys(this.pagination.options)
            .map((key, index) => {
              if (this.pagination.options[key] !== undefined) {
                const value = this.pagination.options[key];
                return `${key}=${value}${
                  Object.keys(this.pagination.options).length - 1 == index
                    ? ""
                    : "&"
                }`;
              }
            })
            .join("");

          let separator = currentUrl.includes("?") ? "&" : "?";

          let newUrl = currentUrl + separator + query_params;

          window.history.pushState({ path: newUrl }, "", newUrl);

          this.fetchReleasesRequest();
        }
      },
    },
    "usersPagination.options": {
      deep: true,
      immediate: true,
      handler() {
        this.newAuthor = null;
        this.setUsers([]);
        this.fetchAllUsersRequest();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

h2 {
  margin-bottom: 16px;
}

.loading {
  width: 100%;
  height: 68px;
  animation: loading 2s linear infinite alternate;
}

@keyframes loading {
  0% {
    background-color: #949494; /* начальный серый цвет */
  }
  100% {
    background-color: #3f4146; /* конечный синий цвет */
  }
}
.releases {
  display: flex;

  .tools-wrapper {
    padding-top: 90px;
    height: min-content;
    position: sticky;
    top: 0;

    .tools {
      padding-top: 30px;
      height: min-content;
      width: 480px;
      margin-right: 25px;

      .release-types {
        display: flex;

        button {
          font-size: 15px;
          padding: 9px;
          flex-grow: 1;
          border: none;
          background: #3f4146;
          color: white;
          cursor: pointer;

          &:first-child {
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }

          &:hover {
            background: #61646a;
          }
        }

        .active {
          transition: 0.6s;
          background: #ed2242;
        }
      }
    }
  }

  &__loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .releases-list {
    flex-grow: 1;
    height: 100%;
    padding-top: 150px;

    .release {
      cursor: pointer;
      border: none;
      width: 100%;
      background: #3f4146;
      border-radius: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin: 0;
        color: white;
      }

      span {
        width: 22%;
        max-width: 200px;
        max-width: 22%;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__status {
        display: flex;
        justify-content: center;
      }

      &__date {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        color: white;
        width: 100px;
      }

      &__transfered {
        display: flex;
        gap: 2px;
        align-items: center;
        padding: 2px 4px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #949494;
        color: #949494;
        font-weight: 500;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .actions {
        padding: 15px;

        a {
          display: block;
          height: 30px;
          width: 30px;
          padding: 5px;
          border-radius: 4px;
          border: none;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
          }

          &:hover {
            background: #61646a;
          }

          &:nth-last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.release-view-wrapper {
  max-height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .release-view {
    width: 1000px;
    padding: 46px;
    border-radius: 9px;
    background: rgba(black, 0.3);
    backdrop-filter: blur(12px);
    margin-bottom: 100px;
    color: white;

    .preview {
      display: flex;
      justify-content: space-between;
      margin-bottom: 46px;
      width: 100%;

      .preview-wrapper {
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        color: white;

        .cover {
          width: 200px;
          height: 200px;
          border-radius: 30px;
          overflow: hidden;
          margin-right: 46px;
          position: relative;

          .edit-btn {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            border: none;
            cursor: pointer;
            opacity: 0;
            transition: 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 40px;
              height: 40px;
            }
          }

          &:hover {
            .edit-btn {
              opacity: 1;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          max-width: calc(100% - 330px);
          overflow: hidden;

          h2 {
            margin: 0 0 24px 0;
            font-size: 48px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span {
            font-size: 20px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: column;

        button {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border: none;
          background: transparent;

          &:active {
            transform: translateY(3px);
          }

          img {
            width: 22px;
            height: 22px;
          }
        }

        a {
          display: block;
          cursor: pointer;
          width: 40px;
          height: 40px;
          border: none;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          &:active {
            transform: translateY(3px);
          }

          img {
            width: 22px;
            height: 22px;
          }

          text-decoration: none;
        }
      }

      .actions {
        display: flex;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          cursor: pointer;
          border: none;
          border-radius: 4px;
          color: white;

          &:active {
            transform: translateY(3px);
          }
        }

        .accept {
          background: #ed2242;
          margin-right: 20px;
          display: flex;
          align-items: center;
        }

        .deny {
          background: #3f4146;
        }
      }
    }

    .info-wrapper {
      color: white;
      margin-bottom: 40px;

      h2 {
        font-size: 25px;
      }

      .tracks {
        display: flex;
        flex-direction: column;
      }

      .artists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .artist {
          margin: 0px 10px 10px 0px;
          widows: 45%;

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }

      .info-items {
        display: flex;

        .info-item {
          margin-right: 50px;

          .info {
            display: flex;
            color: #c2c3c3;
            margin-bottom: 6px;

            span {
              margin-left: 10px;
              color: white;
            }
          }
        }
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__icon--slim {
    width: 20px;
    height: 20px;
  }

  &__icon--slim {
    opacity: 0.5;
  }
}

.modal {
  margin-top: 300px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 9px;
  padding: 20px 40px;
  width: 480px;
  height: min-content;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);

  h2 {
    font-size: 27px;
    color: white;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    color: #61646a;
    line-height: 27px;

    span {
      color: white;
    }
  }

  .actions {
    display: flex;

    .loader {
      max-width: 25px;
      max-height: 25px;
      stroke-width: 7px;
    }
  }

  .input {
    margin-bottom: 20px;
  }

  &--edit {
    max-width: 960px;
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    gap: 0 16px;

    & > * {
      flex-basis: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }

    .cover {
      width: 150px;
      height: 150px;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .actions {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.label {
  display: block;
  color: white;

  .form-item {
    margin-top: 10px;
  }
}

.rotating {
  animation: rotate infinite 1s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tools {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__wide {
    flex-grow: 2;
  }

  &__half {
    flex-grow: 1;
  }

  &__block {
    display: flex;
    flex-direction: column;
    color: white;
    border-radius: 8px;
    background: #3f4146;
    padding: 24px;
  }
}

.releases__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;

  .stats__title {
    margin-right: 5px;
  }

  .stats__item {
    padding: 5px 10px;
    font-size: 16px;
  }
}

.stats {
  &__hr {
    width: 100%;
    height: 1px;
    border: none;
    background: #555555;
    margin: 0 0 16px;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__heading {
    margin-bottom: 4px;
    padding-left: 4px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    background: #555555;

    &__title {
      font-size: 15px;
      margin-right: 10px;
    }

    &__num {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
